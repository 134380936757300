// import floorPlan from "../../../images/Common/floor-plan.svg";
// import masterPlan from "../../../images/Common/master-plan.svg";
// import projectBrochure from "../../../images/Common/project-brochure.svg";
import GSBrochure from "../../../documents/GreenStoreys/GREEN_STOREYS_BROCHURE.pdf";
import GSMasterPlan from "../../../documents/GreenStoreys/Green_Storeys_Master_Plan .pdf";
import GSFloorPlan from "../../../documents/GreenStoreys/Green_Storeys_Tower_and_Floor_Plans.pdf";

const floorPlan = "/images/Common/floor-plan-banner.svg";
const masterPlan = "/images/Common/master-plan-banner.svg";
const projectBrochure = "/images/Common/project-brochure-banner.svg";

export const ProjectResourcesData = [
    {
        key: "floorPlan",
        name: "Floor Plan",
        icon: floorPlan,
        IconAlt: "Floor Plan",
        resourceContent: {
            subject: "Green_Storeys Floor Plan",
            fileName: "Green_Storeys - Floor_Plan.pdf",
            filePath: GSFloorPlan,
        },
    },
    {
        key: "masterPlan",
        name: "Master Plan",
        icon: masterPlan,
        IconAlt: "Master Plan",
        resourceContent: {
            subject: "Green_Storeys Master Plan",
            fileName: "Green_Storeys - Master_Plan.pdf",
            filePath: GSMasterPlan,
        },
    },
    {
        key: "projectBrochure",
        name: "Project Brochure",
        icon: projectBrochure,
        IconAlt: "Project Brochure",
        resourceContent: {
            subject: "Green_Storeys Brochure",
            fileName: "Green_Storeys - Brochure.pdf",
            filePath: GSBrochure,
        },
    },
];
