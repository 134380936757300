import "./Specifications.scss";
import React, { useState } from "react";
import {
    Row,
    Container,
    Accordion,
    Card,
    Col,
    useAccordionToggle,
} from "react-bootstrap";
import { Desc, Heading, DescSmall } from "../../Global/Typography";
import Minus from "../../../images/Common/MinusWhite.svg";
import Plus from "../../../images/Common/PlusWhite.svg";

function CustomToggle({ children, eventKey, handleClick }) {
    const decoratedOnClick = useAccordionToggle(eventKey, () => {
        handleClick();
    });

    return (
        <div className="cardHeader" onClick={decoratedOnClick}>
            {children}
        </div>
    );
}
export default function Specifications({ inView }) {
    const [activeKey, setActiveKey] = useState(0);

    const SpecificationsItems = [
        {
            title: "Architecture",
            list: [
                {
                    label: "Structure",
                    desc: "RCC framed structure with seismic compliance and with solid porotherm block masonry work. Seismic compliance External walls will be of 150 mm thick walls and internal walls will be 150mm/100mm thick",
                },
                {
                    label: "Doors & Windows",
                    items: [
                        "Main Door: Pre-hung - 2100mm high engineered wood frame (150 mm x 65mm width) with approved Veneer, Architrave & 35mm thick both side teak veneer with PU polished shutter with good quality hardware fixtures & digital door lock.",
                        "Bedroom / Toilet door: Pre-hung - 2100mm high engineered wood frame (100 mm x 45 mm) with Architrave and 30mm thick doors with laminate finish for both frame and shutter and good quality hardware fixtures & door lock: Godrej / Ipsal / Equivalent.",
                        "Balcony Access: 2100mm high UPVC sliding doors with mosquito mesh shutter.",
                        "Windows: UPVC sliding windows with mosquito mesh shutter - sill of 100 mm for main windows and sill of 900 mm for cross ventilation windows & lintel 2100mm.",
                        "Toilet Ventilator: (openable) - casement UPVC ventilators with exhaust fan provision for services shaft access from toilet - sill of 600mm & lintel-2100mm.",
                        "Toilet Ventilator: (non-openable) - UPVC ventilators with exhaust fan & fixed louvres - sill of 900mm & lintel-2100mm.",
                        "Kitchen Window: UPVC sliding windows without mesh - lintel 2100mm.",
                    ],
                },
                {
                    label: "Handrails",
                    items: [
                        "Staircase: MS railings as per design (1000mm height).",
                        "Balcony & Corridor: MS railings within concrete frames as per design (1200mm height).",
                        "Terrace: Parapet wall with concrete coping (1200mm height).",
                    ],
                },
                {
                    label: "Ceiling",
                    desc: "",
                    items: [
                        "All internal ceilings: Smooth putty with emulsion paint finish.",
                        "Toilets: Modular grid false ceiling.",
                        "Main entrance lobby on ground floor: Gypsum board false ceiling.",
                        "Balcony: Painted with emulsion paint.",
                    ],
                },
                {
                    label: "Wall Finishes",
                    desc: "",
                    items: [
                        "Internal walls: Smooth putty finish with emulsion paint finish.",
                        "Toilets: 600 x 1200 mm satin matt vitrified wall tiles up to false ceiling (Grid False Ceiling).",
                        "Lift Wall: Granite jamb around lift with texture paint as per design.",
                        "External Walls: Texture finish with external grade weatherproof paint.",
                        "Balcony/Terraces: External grade weatherproof paint.",
                    ],
                },
                {
                    label: "Flooring",
                    desc: "",
                    items: [
                        "Living/Dining/Kitchen/Utility/Bedrooms: 600 x 1200 mm glazed vitrified tiles with skirting.",
                        "Toilet: 600 x 600 mm anti-skid vitrified tiles flooring.",
                        "Balcony: 300 x 300 mm anti-skid ceramic tiles.",
                        "Common Area: 600 x 1200 mm matt vitrified tiles with skirting.",
                        "Staircase: Steel Grey Granite / Equivalent.",
                    ],
                },
            ],
        },
        {
            title: "Electrical & Plumbing",
            list: [
                {
                    label: "Electric Works",
                    items: [
                        "Earthing: Earth Leakage Circuit Breaker (ELCB) & Miniature Circuit Breaker (MCB).",
                        "Electrical Load: 2 BHK - 4KW (Single Phase) | 3 BHK - 5KW (Single Phase).",
                        "Switches: Schneider / Panasonic / Equivalent.",
                        "Water Heaters: Provision in all Toilets & Utility.",
                        "AC: PowerPoint & Drain provision in all Bedrooms and Living & Dining.",
                        "DG Power Backup: Adequate capacity and numbers considering power backup of 1kW DG Power for Units & 100% for Common Area (lighting, pumps, lifts, fire services, WTP, STP, etc.).",
                        "ISI certified cables and wiring through PVC conduits concealed in walls & ceilings with modular switches: light points, fan points, exhaust points, power sockets, call bell point, geyser points, TV points, telephone points, and data points.",
                        "Electrical room to have panel boards & meters as per BESCOM standards.",
                        "Adequate EB power will be provided.",
                    ],
                },
                {
                    label: "Elevators",
                    desc: "Elevators with automatic rescue device & emergency call facility to security Make- Kone / Otis / Schindler / Equivalent",
                },
                {
                    label: "Plumbing/Sanitary Fittings",
                    items: [
                        "All Toilets: EWC - white coloured wall-mounted EWC with seat cover, flush valve, and health faucet (Toto / Jaquar / Equivalent). Wash Basin: White colour counter wash basin with basin mixer (Toto / Jaquar / Equivalent).",
                        "Shower: Shower with single-level diverter and telephonic shower (Jaquar / Equivalent).",
                        "Faucets: Water-efficient CP fixtures (Jaquar / Equivalent).",
                        "Kitchen Sink: Water supply (cold + hot) with hot water supplied from the utility geyser and provision for plumbing lines.",
                    ],
                },
                {
                    label: "Utility",
                    items: [
                        "Sink: Water supply (cold + hot) with geyser in utility and provision for plumbing lines.",
                        "Water Purifier in Utility Above Counter: Plumbing & drain outlet provision.",
                        "Washing Machine: Plumbing & drain outlet provision.",
                        "Dishwasher: Plumbing & drain outlet provision.",
                    ],
                },
            ],
        },

        {
            title: "Other Services",
            list: [
                {
                    label: "CCTV",
                    desc: "Peripheral vigilance through CCTV / cameras at main gate entry/exit, tower entrance & periphery & provision of conduits for CCTV in lobbies",
                },
                {
                    label: "LPG Connection",
                    desc: "Gas Bank with reticulated piped gas system with gas metre (inside utility) monitored by private agency",
                },
                {
                    label: "Car Wash Facility",
                    desc: "Car wash point provided at dedicated space with water inlet & water outlet",
                },
                {
                    label: "Inclusive Community",
                    desc: [
                        "Everyone deserves a place where they feel welcome, valued, and empowered. Our community is designed to foster inclusivity and provide a sense of belonging for all residents.",
                        "Specially abled ramp for easy access to the lobbies.",
                        "Easy access to every level with visual warning signage.",
                        "Dedicated specially abled parking is provided as per norms.",
                    ],
                },
                {
                    label: "Car Charging Points",
                    desc: "Sufficient numbers provided at visitor parking",
                },
            ],
        },
    ];
    // {
    //   title: "Architechture",
    //   list: [
    //     "Architechture - Structure: R.C.C framed structure, designed as per relevant BIS codes for earth quake resistance and structurally efficient systems implemented",
    //     "External walls with 6’’ solid concrete blocks and internal walls with 4’’ solid concrete blocks Plastering",
    //     "Plastering: Sponge finish for external walls, smooth",
    //   ],
    // },
    // {
    //   title: "Electrical",
    //   list: [
    //     "Electrical - Structure: R.C.C framed structure, designed as per relevant BIS codes for earth quake resistance and structurally efficient systems implemented",
    //     "External walls with 6’’ solid concrete blocks and internal walls with 4’’ solid concrete blocks Plastering",
    //     "Plastering: Sponge finish for external walls, smooth",
    //   ],
    // },
    // {
    //   title: "Plumbing, Painting & Services",
    //   list: [
    //     "Plumbing - Structure: R.C.C framed structure, designed as per relevant BIS codes for earth quake resistance and structurally efficient systems implemented",
    //     "External walls with 6’’ solid concrete blocks and internal walls with 4’’ solid concrete blocks Plastering",
    //     "Plastering: Sponge finish for external walls, smooth",
    //   ],
    // },

    return (
        <section
            className={`section  ${inView ? "inView" : "outView"}
  `}
        >
            {/* <Container> */}
            <Container className="Specifications Specifications--black">
                <Row>
                    <Col xs={12} lg={6} className="VerticalDivider">
                        <Heading
                            variant="h1"
                            classList="-title"
                            text="Specifications"
                        />
                        <Desc
                            classList="-desc my-3 "
                            text="The finer details."
                        />
                    </Col>
                    <Col xs={12}>
                        <Accordion className="Accordion" defaultActiveKey={1}>
                            {SpecificationsItems.map((item, index) => (
                                <Card key={index}>
                                    <CustomToggle
                                        className="AccordionHeading"
                                        as={Card.Header}
                                        eventKey={index + 1}
                                        handleClick={() => {
                                            if (activeKey === index) {
                                                setActiveKey(null);
                                            } else {
                                                setActiveKey(index);
                                            }
                                        }}
                                    >
                                        <Heading
                                            variant="h4"
                                            classList="-title my-0 mr-5 pr-5"
                                            text={item.title}
                                        />
                                        <img
                                            className="PlusMinus"
                                            src={
                                                activeKey === index
                                                    ? Minus
                                                    : Plus
                                            }
                                            alt={"Minus Plus"}
                                        />
                                    </CustomToggle>
                                    <Accordion.Collapse eventKey={index + 1}>
                                        <Card.Body className="">
                                            <ul className="Accordion__list px-3">
                                                {item.list.map(
                                                    (
                                                        { label, desc, items },
                                                        i0
                                                    ) => {
                                                        return (
                                                            <div key={i0}>
                                                                <Heading
                                                                    variant="h7"
                                                                    // classList="-desc"
                                                                    text={label}
                                                                />

                                                                <li className="mb-2">
                                                                    {desc ? (
                                                                        <DescSmall
                                                                            classList="my-2"
                                                                            text={
                                                                                desc
                                                                            }
                                                                        />
                                                                    ) : null}

                                                                    {items
                                                                        ? items.map(
                                                                              (
                                                                                  it,
                                                                                  i1
                                                                              ) => {
                                                                                  return (
                                                                                      <DescSmall
                                                                                          key={
                                                                                              i1
                                                                                          }
                                                                                          classList="mt-2 mb-0"
                                                                                          text={
                                                                                              it
                                                                                          }
                                                                                      />
                                                                                  );
                                                                              }
                                                                          )
                                                                        : null}
                                                                </li>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </ul>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            ))}
                        </Accordion>
                    </Col>
                </Row>
            </Container>
        </section>
    );
}
