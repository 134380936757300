import { useRef, useState } from "react";
import { Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import mobileMenuIcon from "../../images/mobile-menu-icon.svg";
import ComingSoon from "../Global/ComingSoon";
import GetInTouchForm from "../Global/ContactUsModal/GetInTouchForm";
import NewLaunchProjectModal from "../Global/NewLaunchProjectModal/NewLaunchProjectModal";
import MobileMenu from "../mobile-menu/mobile-menu";
import {
    completedProjects,
    ongoingProjects,
    upcomingProjects,
} from "./constants";
import "./header.scss";
// import GetInTouchForm from "../Global/GetInTouchForm";

const msTextLogo = "/images/modern-spaaces-text-logo.svg";
const ivyCountyLogo = "/images/IvyCounty/ivy-county-logo.png";

export default function Header({ isShowLinks, hideCta = false }) {
    const [isShowProjectMenu, setIsShowProjectMenu] = useState(false);
    const [showContactUsModal, setShowContactUsModal] = useState(false);
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [showNewLaunchModal, setShowNewLaunchModal] = useState(false);
    const [reraApproved, setReraApproved] = useState(false);
    const [reraNumber, setReraNumber] = useState("");
    const [show, setShow] = useState(false);
    const projectMenuRef = useRef(null);
    const handleShow = (e) => {
        e.preventDefault();
        setShow(true);
    };
    const handleClose = () => setShow(false);

    // const [show1, setShow1] = useState(false);
    // const handleShow1 = (e) => {
    //   e.preventDefault();
    //   setShow1(true);
    // };
    // const handleClose1 = () => setShow1(false);

    const toggleMobileMenu = () => setShowMobileMenu((prev) => !prev);

    //   const toggleDesktopMenu = () => {
    //     const val = !isShowDesktopMenu;
    //     setIsShowDesktopMenu(val);
    //   };

    const toggleProjectMenu = () => {
        // const val = !isShowProjectMenu;
        setIsShowProjectMenu((val) => !val);
    };

    useOutsideClick(projectMenuRef, () => setIsShowProjectMenu(false));

    const createHeading = (h) => (
        <>
            <div
                style={{
                    color: "rgba(255, 255, 255, 0.42)",
                    fontFamily: "BentonSansBook",
                    fontSize: "0.875rem",
                }}
            >
                {h}
            </div>
            <span
                className="v-line my-2"
                style={{
                    backgroundColor: "rgba(255, 255, 255, 0.42)",
                }}
            ></span>
        </>
    );

    const renderProjectTag = (label) => {
        return <div className="project-tag">{label}</div>;
    };

    const createRow = (r) => (
        <div className="d-flex flex-column" style={{ gap: "0.5rem" }}>
            {r.map(
                ({
                    label,
                    link,
                    type,
                    soldOut,
                    unitsLeft,
                    reraApproved,
                    reraNumber,
                }) => {
                    switch (type) {
                        case "upcoming":
                            return (
                                <div className="item-link menu-item fc-white m-0">
                                    {label}
                                </div>
                            );
                        case "newLaunch":
                            return (
                                <a
                                    href="javascript;"
                                    className="item-link menu-item fc-white m-0"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setShowNewLaunchModal(true);
                                        if (reraApproved) {
                                            setReraApproved(true);
                                            setReraNumber(reraNumber);
                                        } else {
                                            setReraApproved(false);
                                        }
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    {label}
                                </a>
                            );
                        default:
                            return (
                                <Link
                                    to={link}
                                    onClick={toggleProjectMenu}
                                    className="item-link menu-item fc-white m-0 d-flex align-items-center"
                                >
                                    <>
                                        <span>{label}</span>
                                        {!!soldOut &&
                                            renderProjectTag("Sold Out")}
                                        {!!unitsLeft &&
                                            !soldOut &&
                                            renderProjectTag(
                                                `${unitsLeft} Units Left`
                                            )}
                                    </>
                                </Link>
                            );
                    }
                }
            )}
        </div>
    );

    return (
        <>
            <header>
                <Navbar className="section-header bg-black w-100">
                    <div className="content d-flex justify-content-between w-100 align-items-center position-relative">
                        {hideCta ? (
                            <a href="/" title="Modern Spaaces">
                                <img
                                    className="header-logo mt-n1"
                                    alt="Modern Spaaces"
                                    src={ivyCountyLogo}
                                    style={{
                                        height: "72px",
                                        width: "auto",
                                    }}
                                />
                            </a>
                        ) : (
                            <a href="/" title="Ivy County">
                                <img
                                    className="header-logo mt-n1"
                                    alt="Ivy County"
                                    src={msTextLogo}
                                />
                            </a>
                        )}
                        <div className="navigation d-none d-sm-flex justify-content-end align-items-center bg-black ">
                            <div
                                className="main-links align-items-center"
                                style={{
                                    gridTemplateColumns: hideCta
                                        ? "1fr 1fr"
                                        : "",
                                }}
                            >
                                {!hideCta && (
                                    <span
                                        className={`link-wrapper ${
                                            isShowProjectMenu ? "show" : "hide"
                                        }`}
                                        ref={projectMenuRef}
                                    >
                                        <button
                                            className={`item-link d-flex align-items-center ${
                                                isShowProjectMenu
                                                    ? "project-menu-open"
                                                    : ""
                                            }  fc-white `}
                                            style={{
                                                gap: "",
                                                padding: "0",
                                                paddingRight: "0.75rem",
                                            }}
                                            onClick={toggleProjectMenu}
                                        >
                                            <span
                                                style={{
                                                    width: "4rem",
                                                    textAlign: "left",
                                                }}
                                            >
                                                Projects
                                            </span>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="13"
                                                height="7"
                                                viewBox="0 0 13 7"
                                                fill="none"
                                                className="icon icon-arrow"
                                                {...(isShowProjectMenu
                                                    ? {
                                                          style: {
                                                              transformOrigin:
                                                                  "0% 0%",
                                                              transform:
                                                                  "rotate(180deg) translateX(-100%) translateY(-75%)",
                                                          },
                                                      }
                                                    : {})}
                                            >
                                                <path
                                                    d="M12 0.5L6.5 5.5L1 0.5"
                                                    className={`${
                                                        isShowProjectMenu
                                                            ? "yellow-stroke"
                                                            : ""
                                                    }`}
                                                />
                                            </svg>
                                        </button>
                                        {
                                            <div
                                                className={`item-link-menu px-4 py-3 bg-black text-white mt-8 navbar-menu-responsive`}
                                                style={{
                                                    width: "auto",
                                                    gap: "2rem",
                                                }}
                                            >
                                                <div
                                                    className="d-flex flex-column"
                                                    style={{ color: "#D9D9D9" }}
                                                >
                                                    {createHeading(
                                                        "Ongoing Projects"
                                                    )}
                                                    {createRow(ongoingProjects)}
                                                </div>
                                                <div
                                                    className="d-flex flex-column"
                                                    style={{ color: "#D9D9D9" }}
                                                >
                                                    {createHeading(
                                                        "Upcoming Projects"
                                                    )}

                                                    {createRow(
                                                        upcomingProjects
                                                    )}
                                                </div>
                                                <div className="d-flex flex-column">
                                                    {createHeading(
                                                        "Completed Projects"
                                                    )}
                                                    {createRow(
                                                        completedProjects
                                                    )}
                                                </div>

                                                {/* <div className="d-flex flex-column"></div>
                                        <div className="d-flex flex-column"></div> */}
                                                {/* <span className="v-line bg-light-1"></span> */}
                                                {/*
                                        <span className="v-line bg-light-1"></span>

                                        <span className="v-line bg-light-1"></span>
                                       {" "}
                                        */}
                                                {/*

                    <span className="v-line bg-light-1"></span>
                    <Link
                      to="/"
                      className="item-link fc-white pl-4 pr-4 pt-2 pb-3 m-0"
                    >
                      Windmere
                    </Link> */}
                                            </div>
                                        }
                                    </span>
                                )}

                                <span>
                                    <a
                                        href={
                                            hideCta
                                                ? "https://modernspaaces.com/#about-us"
                                                : "/#about-us"
                                        }
                                        target={hideCta ? "_blank" : ""}
                                        // onClick={handleShow}
                                        className="item-link fc-white d-none d-sm-flex font-work-sans"
                                        rel="noreferrer"
                                    >
                                        About Us
                                    </a>
                                </span>
                                <span>
                                    <a
                                        href={
                                            hideCta
                                                ? "https://modernspaaces.com/blogs"
                                                : "/blogs"
                                        }
                                        target={hideCta ? "_blank" : ""}
                                        className="item-link fc-white font-work-sans "
                                        rel="noreferrer"
                                    >
                                        Blogs
                                    </a>
                                </span>
                            </div>
                            <div
                                className={`extra-links align-items-center d-flex  ${
                                    isShowLinks ? "show" : "hide"
                                }`}
                            >
                                <span className="separator-v d-none d-md-flex"></span>
                                <a
                                    href={
                                        hideCta
                                            ? "tel:+91 7760 838 666"
                                            : "tel:+91 9008 288 288"
                                    }
                                    className="item-link fc-white mobile-link  d-none d-md-flex"
                                >
                                    {hideCta
                                        ? "+91 7760 838 666"
                                        : "+91 9008 288 288"}
                                </a>
                                {!hideCta && (
                                    <>
                                        <span className="separator-v-small d-none d-lg-flex"></span>

                                        <a
                                            href="mailto:info@modernspaaces.com"
                                            className="item-link fc-white email-link d-none d-lg-flex"
                                        >
                                            info@modernspaaces.com
                                        </a>
                                    </>
                                )}
                            </div>

                            {/* <div onClick={handleShow1}> */}
                            <button
                                className="app-btn--hollow--primary app-btn--small d-none d-sm-inline"
                                onClick={() => {
                                    setShowContactUsModal(true);
                                }}
                            >
                                <span className="btn-text">Contact Us</span>
                            </button>

                            {/* </div> */}
                        </div>

                        <div
                            className="d-flex d-sm-none"
                            onClick={toggleMobileMenu}
                        >
                            <img src={mobileMenuIcon} alt={"menu"} />
                        </div>
                        {/* <a
                            href="tel:+91 9008 288 288"
                            className="item-link fc-white mobile-tel"
                        >
                            +91 9008 288 288
                        </a> */}
                        {/* <i
                            className={`d-md-none ml-auto icon-toggle-nav ${
                                isShowMobileMenu ? "icon-close" : ""
                            }`}
                            onClick={toggleMobileMenu}
                        ></i>
                        <i
                            className={`d-none d-md-block icon-toggle-nav ml-5 ${
                                isShowDesktopMenu ? "icon-close" : ""
                            }`}
                            onClick={toggleDesktopMenu}
                        ></i> */}
                    </div>
                </Navbar>

                {/* <MobileMenu
          isShowMobileMenu={isShowMobileMenu}
          toggleMobileMenu={toggleMobileMenu}
        ></MobileMenu>
        <DesktopMenu
          isShowDesktopMenu={isShowDesktopMenu}
          toggleDesktopMenu={toggleDesktopMenu}
        ></DesktopMenu> */}
            </header>
            <GetInTouchForm
                show={showContactUsModal}
                IsNotAPlan={true}
                handleClose={() => setShowContactUsModal(false)}
            />
            <MobileMenu
                hideCta={hideCta}
                isShowMobileMenu={showMobileMenu}
                toggleMobileMenu={toggleMobileMenu}
            ></MobileMenu>
            <NewLaunchProjectModal
                show={showNewLaunchModal}
                handleClose={() => setShowNewLaunchModal(false)}
                reraApproved={reraApproved}
                reraNumber={reraNumber}
            />
            {/* ComingSoon */}
            <ComingSoon show={show} handleClose={handleClose} />
            {/* GetInTouchForm
      <GetInTouchForm
        IsNotAPlan={false}
        show={show1}
        handleClose={handleClose1}
      /> */}
        </>
    );
}

// <header>
//                 <Navbar className="section-header bg-black w-100">
//                     <div className="content d-flex w-100 align-items-center">
//                         <a href="/" title="Modern Spaaces">
//                             <h1 className="logo" alt="Modern Spaaces">
//                                 {" "}
//                             </h1>
//                         </a>
//                         <div className="navigation d-none d-md-flex justify-content-end align-items-center bg-black ml-auto">
//                             <div className="main-links d-flex align-items-center">
//                                 <div className="link-wrapper">
//                                     <a
//                                         href="javascript;"
//                                         className="item-link fc-white"
//                                         onMouseEnter={toggleProjectMenu}
//                                         onMouseLeave={toggleProjectMenu}
//                                         onClick={(e) => e.preventDefault()}
//                                     >
//                                         <span>Projects</span>
//                                         <svg
//                                             xmlns="http://www.w3.org/2000/svg"
//                                             width="13"
//                                             height="7"
//                                             viewBox="0 0 13 7"
//                                             fill="none"
//                                             className="icon icon-arrow ml-2"
//                                         >
//                                             <path d="M12 0.5L6.5 5.5L1 0.5" />
//                                         </svg>
//                                     </a>
//                                     <div
//                                         className={`item-link-menu p-2 bg-black d-flex flex-column`}
//                                     >
//                                         <Link
//                                             to="Soulace"
//                                             className="item-link fc-white pl-4 pr-4 pt-2 pb-3 m-0"
//                                         >
//                                             Soulace
//                                         </Link>
//                                         <span className="v-line bg-light-1"></span>
//                                         <Link
//                                             to="Engrace"
//                                             className="item-link fc-white pl-4 pr-4 pt-2 pb-3 m-0"
//                                         >
//                                             Engrace
//                                         </Link>
//                                         <span className="v-line bg-light-1"></span>
//                                         <Link
//                                             to="SoulTree"
//                                             className="item-link fc-white pl-4 pr-4 pt-3 pb-2 m-0"
//                                         >
//                                             SoulTree
//                                         </Link>

//                                         <span className="v-line bg-light-1"></span>
//                                         <Link
//                                             to="urbanest"
//                                             className="item-link fc-white pl-4 pr-4 pt-2 pb-3 m-0"
//                                         >
//                                             Urbanest
//                                         </Link>

//                                         {/* <span className="v-line bg-light-1"></span>

//                     <span className="v-line bg-light-1"></span>
//                     <Link
//                       to="/"
//                       className="item-link fc-white pl-4 pr-4 pt-2 pb-3 m-0"
//                     >
//                       Windmere
//                     </Link> */}
//                                     </div>
//                                 </div>
//                                 <span>
//                                     <a
//                                         href="javascript;"
//                                         onClick={handleShow}
//                                         className="item-link fc-white"
//                                     >
//                                         About Us
//                                     </a>
//                                 </span>
//                             </div>
//                             <div
//                                 className={`extra-links d-flex align-items-center ${
//                                     isShowLinks ? "show" : "hide"
//                                 }`}
//                             >
//                                 <span className="separator-v"></span>
//                                 <a
//                                     href="tel:+91 9008 288 288"
//                                     className="item-link fc-white"
//                                 >
//                                     +91 9008 288 288
//                                 </a>
//                                 <a
//                                     href="mailto:info@modernspaaces.com"
//                                     className="item-link fc-white"
//                                 >
//                                     info@modernspaaces.com
//                                 </a>
//                             </div>
//                             {/* <div onClick={handleShow1}> */}
//                             <Link to="/contact-us">
//                                 <button className="app-btn--hollow--white px-4 px-4 py-2 app-btn--small">
//                                     <span className="app-btn-text">
//                                         Plan a visit
//                                     </span>
//                                 </button>
//                             </Link>
//                             {/* </div> */}
//                         </div>
//                         <a
//                             href="tel:+91 9008 288 288"
//                             className="item-link fc-white mobile-tel"
//                         >
//                             +91 9008 288 288
//                         </a>
//                         <i
//                             className={`d-md-none ml-auto icon-toggle-nav ${
//                                 isShowMobileMenu ? "icon-close" : ""
//                             }`}
//                             onClick={toggleMobileMenu}
//                         ></i>
//                         <i
//                             className={`d-none d-md-block icon-toggle-nav ml-5 ${
//                                 isShowDesktopMenu ? "icon-close" : ""
//                             }`}
//                             onClick={toggleDesktopMenu}
//                         ></i>
//                     </div>
//                 </Navbar>
//
//                 <DesktopMenu
//                     isShowDesktopMenu={isShowDesktopMenu}
//                     toggleDesktopMenu={toggleDesktopMenu}
//                 ></DesktopMenu>
//             </header>
