import { Route, Switch } from "react-router-dom";
import ContactUs from "./contact-us";
import Disclaimer from "./disclaimer";
import PrivacyPolicy from "./privacy-policy";
// import BlogDetail from "./pages/BlogDetail";
import ThankYouContact from "./ThankYouContact";
// useRef
import { useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { Header } from "./components/index";

import IvyCountyStandalone from "./pages/IvyCountyStandalone";
import TermsAndConditionsPage from "./pages/TermsAndConditions";

export default function ProjectApp() {
    const [isShowLinks, setIsShowLinks] = useState(false);
    let location = useLocation();

    const handleScroll = (event) => {
        let offsetTop = document.documentElement.scrollTop;
        if (offsetTop > 80) {
            setIsShowLinks(true);
        } else {
            setIsShowLinks(false);
        }
    };

    window.addEventListener("scroll", handleScroll);

    useEffect(() => {
        // document.documentElement.scrollTo(0, 0);
        window.scrollTo({ top: 0, left: 0, behavior: "instant" });
        console.log(location);
    }, [location]);

    return (
        <>
            <HelmetProvider>
                <article className="app-container p-0 container-fluid">
                    <Header isShowLinks={isShowLinks} hideCta={true}></Header>

                    <Switch>
                        <Route exact path="/" component={IvyCountyStandalone} />
                        <Route path="/contact-us" component={ContactUs}></Route>
                        <Route
                            path="/thank-you-contact"
                            component={ThankYouContact}
                        ></Route>
                        <Route
                            path="/disclaimer"
                            component={Disclaimer}
                        ></Route>
                        <Route
                            path="/privacy-policy"
                            component={PrivacyPolicy}
                        ></Route>
                        <Route
                            path="/terms-and-conditions"
                            component={TermsAndConditionsPage}
                        />
                    </Switch>
                </article>
            </HelmetProvider>
        </>
    );
}
