import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Desc, DescLabel } from "../../../Global/Typography";
import BannerNew from "../../BannerNew";
import "./index.scss";

import { Accordion, Card } from "react-bootstrap";
import BlogData from "../../../../db/BlogSpotlightOnMS";
import ShareButton from "../../Share/ShareButton";
import { BLOGS } from "../../constants";

window.scrollToElement = function (id) {
    const el = document.querySelector(id);
    if (el) {
        window.scrollTo({
            behavior: "smooth",
            top: 200,
        });
    }
};

export default function Banner({ inView }) {
    const [activeKey, setActiveKey] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const toggleAccordion = (key) => {
        setActiveKey(activeKey === key ? null : key);
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <title>{BLOGS.SPOTLIGHT_ON_MS.title}</title>
                <meta
                    name="Keywords"
                    content="" //TODO
                />
                <meta
                    name="description"
                    content={BLOGS.SPOTLIGHT_ON_MS.meta}
                ></meta>
                <link
                    rel="canonical"
                    href={`https://modernspaaces.com/blogs/${BLOGS.SPOTLIGHT_ON_MS.slug}`}
                />
            </Helmet>
            <section
                className={`section moving-out-made-easy  ${
                    inView ? "inView" : "outView"
                }`}
            >
                <BannerNew
                    image={BlogData.image}
                    title={BlogData.title}
                    publishedOn={BlogData.publishedOn}
                    time={BlogData.time}
                    alt={BlogData.imageAlt}
                    position={"center"}
                />
                <Container className="blog-detail-container">
                    <Row className="justify-content-around">
                        <Col xs={12} md={11} lg={9} className="pb-4 pr-md-5">
                            <div className="blog-header margin">
                                {BlogData.description11 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description11}
                                    />
                                )}
                                {BlogData.title2 && (
                                    <p className="-descBlack font-benton-bold margin mb-3">
                                        {BlogData.title2}
                                    </p>
                                )}
                                {BlogData.description21 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description21}
                                    />
                                )}
                                {!isPlaying && (
                                    <div
                                        className="imagepadding"
                                        onClick={() => setIsPlaying(true)}
                                    >
                                        <img
                                            src={BlogData.image3}
                                            alt="Video"
                                            class="image margin"
                                            style={{
                                                cursor: "pointer",
                                            }}
                                        />
                                    </div>
                                )}

                                {isPlaying && (
                                    <iframe
                                        className="imagepadding image"
                                        width="100%"
                                        src={
                                            // "https://youtu.be/embed/Sw5M9uRsoEc?si=cSxn0TRmUmXl6sOp",
                                            "https://www.youtube.com/embed/Sw5M9uRsoEc?autoplay=1"
                                        }
                                        title="Sarjapur Road- The future of new Bangalore | On the way to Engrace Apts &amp; Soulace Villas"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowFullScreen
                                        style={{
                                            overflow: "hidden",
                                            // height: '100%',
                                            width: "100%",
                                        }}
                                    />
                                )}
                                {BlogData.title3 && (
                                    <p className="-descBlack font-benton-bold margin mb-3">
                                        {BlogData.title3}
                                    </p>
                                )}
                                {BlogData.description31 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description31}
                                    />
                                )}
                                {BlogData.title4 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title4}
                                    </p>
                                )}
                                {BlogData.description41 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description41}
                                    />
                                )}
                                {BlogData.title5 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title5}
                                    </p>
                                )}
                                {BlogData.description51 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description51}
                                    />
                                )}
                                {BlogData.title6 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title6}
                                    </p>
                                )}
                                {BlogData.list61 && (
                                    <ul className="blog-list-circle  header">
                                        {BlogData.list61.map((i1, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1  font-benton-book header "
                                                        text={i1.description}
                                                        label={i1.label}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                                {BlogData.title7 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title7}
                                    </p>
                                )}
                                {BlogData.description71 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description71}
                                    />
                                )}
                                {BlogData.title8 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title8}
                                    </p>
                                )}
                                {BlogData.description81 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description81}
                                    />
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row className="simpleShadow my-3 py-3 justify-content-around">
                        <Col
                            xs={12}
                            md={11}
                            lg={9}
                            className="pb-4 pr-md-5 p-2"
                        >
                            <Row>
                                <div
                                    className="blog-content px-3"
                                    style={{
                                        width: "-webkit-fill-available",
                                    }}
                                >
                                    {BlogData.Faq && (
                                        <p className="-descBlack font-benton-bold  margin header2">
                                            {BlogData.Faq}
                                        </p>
                                    )}
                                    {BlogData.faqs &&
                                        BlogData.faqs.map((faq, faqIdx) => (
                                            <Accordion
                                                key={faqIdx}
                                                defaultActiveKey={faqIdx.toString()}
                                                activeKey={activeKey}
                                            >
                                                <Card
                                                    key={faqIdx}
                                                    className="my-2 p-2 font-benton-bold"
                                                    style={{
                                                        width: "fit",
                                                        border: "none",
                                                        backgroundColor:
                                                            "rgba(0, 0, 0, 0.02)",
                                                        borderRadius: "0.5rem",
                                                    }}
                                                >
                                                    <Accordion.Toggle
                                                        as={Card.Header}
                                                        eventKey={`${faqIdx}`}
                                                        className="font-benton-bold faq"
                                                        style={{
                                                            border: "none",
                                                            background: "none",
                                                        }}
                                                        onClick={() =>
                                                            toggleAccordion(
                                                                faqIdx.toString()
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "space-between",
                                                                cursor: "pointer",
                                                                borderBottom:
                                                                    "none",
                                                            }}
                                                        >
                                                            {faq.label}
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="20"
                                                                height="21"
                                                                viewBox="0 0 20 21"
                                                                fill="none"
                                                                style={{
                                                                    transform:
                                                                        activeKey ===
                                                                        faqIdx.toString()
                                                                            ? "rotate(180deg)"
                                                                            : "rotate(0deg)",
                                                                }}
                                                            >
                                                                <path
                                                                    d="M16.5984 7.96094L11.1651 13.3943C10.5234 14.0359 9.47344 14.0359 8.83177 13.3943L3.39844 7.96094"
                                                                    stroke="black"
                                                                    strokeOpacity="0.32"
                                                                    strokeWidth="2"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        </div>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse
                                                        eventKey={`${faqIdx}`}
                                                    >
                                                        <Card.Body className="font-benton-book header p-3">
                                                            <Card.Body className="font-benton-book header p-3">
                                                                {
                                                                    faq.description
                                                                }
                                                            </Card.Body>
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        ))}
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="simpleShadow my-3 py-3 justify-content-around">
                        <Col
                            xs={12}
                            md={11}
                            lg={9}
                            className="pb-4 pr-md-5 p-2"
                        >
                            <div
                                className="shareLinkBlock1 d-flex flex-row p-4 "
                                style={{
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    gap: "1rem",
                                    borderRadius: "1rem",
                                }}
                            >
                                <ShareButton
                                    shareUrl={`https://modernspaaces.com/blogs/${BLOGS.PLOTTED_DEVELOPMENT.slug}`}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}
