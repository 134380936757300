import React, { Component } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const Gallery1 = "/images/GreenStoreys/Banner/banner.webp";
const Gallery2 = "/images/GreenStoreys/gallery/gallery-2.png";
const Gallery3 = "/images/GreenStoreys/gallery/gallery-3.png";
const Gallery4 = "/images/GreenStoreys/gallery/gallery-4.png";
const Gallery5 = "/images/GreenStoreys/gallery/gallery-5.png";
const Gallery6 = "/images/GreenStoreys/gallery/gallery-6.png";

const images = [Gallery1, Gallery2, Gallery3, Gallery4, Gallery5, Gallery6];
export default class GSlider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            photoIndex: 0,
            isOpen: false,
        };
    }

    render() {
        const { photoIndex, isOpen } = this.state;

        return (
            <div className="GSliderHoverEle">
                <img
                    // title="Click to View More Images"
                    className="LightHouseImage"
                    src={Gallery1}
                    alt="LightHouseImage"
                    onClick={() => this.setState({ isOpen: true })}
                />

                <div
                    className="videoIconImage videoIconImage--text"
                    onClick={() => this.setState({ isOpen: true })}
                >
                    View
                </div>
                {/* <div className="divBl">Click to View More Images</div> */}
                {isOpen && (
                    <Lightbox
                        mainSrc={images[photoIndex]}
                        nextSrc={images[(photoIndex + 1) % images.length]}
                        prevSrc={
                            images[
                                (photoIndex + images.length - 1) % images.length
                            ]
                        }
                        // imageTitle={imagesCaption[photoIndex]}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex:
                                    (photoIndex + images.length - 1) %
                                    images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length,
                            })
                        }
                    />
                )}
            </div>
        );
    }
}
