import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Helmet } from "react-helmet-async";
import { Desc, DescLabel } from "../../../Global/Typography";
import BannerNew from "../../BannerNew";
import "./index.scss";

import { Accordion, Card } from "react-bootstrap";
import BlogData from "../../../../db/BlogDecodingAreaMetrics";
import ShareButton from "../../Share/ShareButton";
import { BLOGS } from "../../constants";

window.scrollToElement = function (id) {
    const el = document.querySelector(id);
    if (el) {
        window.scrollTo({
            behavior: "smooth",
            top: 200,
        });
    }
};

export default function Banner({ inView }) {
    const [activeKey, setActiveKey] = useState(null);

    const toggleAccordion = (key) => {
        setActiveKey(activeKey === key ? null : key);
    };
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0"
                />
                <title>{BLOGS.DECODING_AREA_METRICS.title}</title>
                <meta
                    name="Keywords"
                    content="" //TODO
                />
                <meta
                    name="description"
                    content={BLOGS.DECODING_AREA_METRICS.meta}
                ></meta>
                <link
                    rel="canonical"
                    href={`https://modernspaaces.com/blogs/${BLOGS.DECODING_AREA_METRICS.slug}`}
                />
            </Helmet>
            <section className={`section   ${inView ? "inView" : "outView"}`}>
                <BannerNew
                    image={BlogData.image}
                    title={BlogData.title}
                    publishedOn={BlogData.publishedOn}
                    time={BlogData.time}
                    alt={BlogData.imageAlt}
                    imageFit={"cover"}
                />
                <Container className="blog-detail-container">
                    <Row className="justify-content-around">
                        <Col xs={12} md={11} lg={9} className="pb-4 pr-md-5">
                            <div className="blog-header margin">
                                {BlogData.title1 && (
                                    <p className="-descBlack font-benton-bold">
                                        {BlogData.title1}
                                    </p>
                                )}
                                {BlogData.description11 && (
                                    <Desc
                                        classList="font-benton-bold header"
                                        text={BlogData.description11}
                                    />
                                )}
                                {BlogData.title2 && (
                                    <p className="-descBlack font-benton-bold margin mb-3">
                                        {BlogData.title2}
                                    </p>
                                )}
                                {BlogData.description21 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description21}
                                    />
                                )}
                                {BlogData.title3 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title3}
                                    </p>
                                )}
                                {BlogData.description31 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description31}
                                    />
                                )}
                                {BlogData.list31 && (
                                    <ul className="blog-list-circle  header">
                                        {BlogData.list31.map((i1, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1 font-benton-book header "
                                                        text={i1.description}
                                                        label={i1.label}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                                {BlogData.description32 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description32}
                                    />
                                )}
                                <div className="imagepadding">
                                    <img
                                        src={BlogData.image2}
                                        alt={BlogData.image2Alt}
                                        class="image margin border"
                                    />
                                </div>
                                {BlogData.title4 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title4}
                                    </p>
                                )}
                                {BlogData.list41 && (
                                    <ul className=" header">
                                        {BlogData.list41.map((i0, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1 font-benton-book header "
                                                        text={i0.description}
                                                        label={i0.label}
                                                    />
                                                    {i0.list && (
                                                        <ul className="blog-list-circle header">
                                                            {i0.list.map(
                                                                (i1, idx1) => {
                                                                    return (
                                                                        <li
                                                                            key={
                                                                                idx1
                                                                            }
                                                                        >
                                                                            <p className="-descBlack mb-1 font-benton-book header">
                                                                                {
                                                                                    i1
                                                                                }
                                                                            </p>
                                                                        </li>
                                                                    );
                                                                }
                                                            )}
                                                        </ul>
                                                    )}
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                                {BlogData.title5 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title5}
                                    </p>
                                )}
                                {BlogData.description51 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description51}
                                    />
                                )}

                                {BlogData.title6 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title6}
                                    </p>
                                )}
                                {BlogData.description61 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description61}
                                    />
                                )}
                                {BlogData.title7 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title7}
                                    </p>
                                )}
                                {BlogData.description71 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description71}
                                    />
                                )}
                                {BlogData.list72 && (
                                    <ul className="blog-list-circle  header">
                                        {BlogData.list72.map((i1, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1 font-benton-book header "
                                                        text={i1.description}
                                                        label={i1.label}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                                {BlogData.title8 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title8}
                                    </p>
                                )}
                                {BlogData.description81 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description81}
                                    />
                                )}
                                {BlogData.title9 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title9}
                                    </p>
                                )}
                                {BlogData.description91 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description91}
                                    />
                                )}
                                {BlogData.title10 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title10}
                                    </p>
                                )}
                                {BlogData.description101 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description101}
                                    />
                                )}
                                {BlogData.list102 && (
                                    <ul className="blog-list-circle  header">
                                        {BlogData.list102.map((i1, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1 font-benton-book header "
                                                        text={i1.description}
                                                        label={i1.label}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}

                                <div className="imagepadding">
                                    <img
                                        src={BlogData.image4}
                                        alt={BlogData.image4Alt}
                                        class="image margin border"
                                    />
                                </div>
                                <div className="">
                                    <img
                                        src={BlogData.image5}
                                        alt={BlogData.image5Alt}
                                        class="image margin"
                                    />
                                </div>
                                {BlogData.title11 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title11}
                                    </p>
                                )}
                                {BlogData.description111 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description111}
                                    />
                                )}
                                {BlogData.title12 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title12}
                                    </p>
                                )}
                                {BlogData.description121 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description121}
                                    />
                                )}

                                {BlogData.title131 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title131}
                                    </p>
                                )}
                                {BlogData.description131 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description131}
                                    />
                                )}
                                {BlogData.title14 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title14}
                                    </p>
                                )}
                                {BlogData.description141 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description141}
                                    />
                                )}
                                {BlogData.title15 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title15}
                                    </p>
                                )}
                                {BlogData.list151 && (
                                    <ul className="blog-list-circle  header">
                                        {BlogData.list151.map((i1, idx1) => {
                                            return (
                                                <li key={idx1}>
                                                    <DescLabel
                                                        classList="my-1 font-benton-book header "
                                                        text={i1.description}
                                                        label={i1.label}
                                                    />
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                                {/* <div className="comparison-table-wrapper">
                                    <table className="comparison-table">
                                        <thead>
                                            <tr>
                                                {BlogData.table163header.map(
                                                    (headerItem) => {
                                                        return (
                                                            <th>
                                                                <p className="font-benton-book mb-0">
                                                                    {headerItem}
                                                                </p>
                                                            </th>
                                                        );
                                                    }
                                                )}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {BlogData.table163.map(
                                                (rowItem, idx) => {
                                                    return (
                                                        <tr key={idx}>
                                                            <>
                                                                <td>
                                                                    <Desc
                                                                        classList="my-1 font-benton-bold header "
                                                                        text={
                                                                            rowItem.col1
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Desc
                                                                        classList="font-benton-book header mb-0"
                                                                        text={
                                                                            rowItem.col2
                                                                        }
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Desc
                                                                        classList="font-benton-book header mb-0"
                                                                        text={
                                                                            rowItem.col3
                                                                        }
                                                                    />
                                                                </td>
                                                            </>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                        </tbody>
                                    </table>
                                </div> */}
                                {BlogData.title16 && (
                                    <p className="-descBlack font-benton-bold  margin">
                                        {BlogData.title16}
                                    </p>
                                )}
                                {BlogData.description161 && (
                                    <Desc
                                        classList="font-benton-book header"
                                        text={BlogData.description161}
                                    />
                                )}
                            </div>
                        </Col>
                    </Row>
                    <Row className="simpleShadow my-3 py-3 justify-content-around">
                        <Col
                            xs={12}
                            md={11}
                            lg={9}
                            className="pb-4 pr-md-5 p-2"
                        >
                            <Row>
                                <div
                                    className="blog-content px-3"
                                    style={{
                                        width: "-webkit-fill-available",
                                    }}
                                >
                                    {BlogData.Faq && (
                                        <p className="-descBlack font-benton-bold  margin header2">
                                            {BlogData.Faq}
                                        </p>
                                    )}
                                    {BlogData.faqs &&
                                        BlogData.faqs.map((faq, faqIdx) => (
                                            <Accordion
                                                key={faqIdx}
                                                defaultActiveKey={faqIdx.toString()}
                                                activeKey={activeKey}
                                            >
                                                <Card
                                                    key={faqIdx}
                                                    className="my-2 p-2 font-benton-bold"
                                                    style={{
                                                        width: "fit",
                                                        border: "none",
                                                        backgroundColor:
                                                            "rgba(0, 0, 0, 0.02)",
                                                        borderRadius: "0.5rem",
                                                    }}
                                                >
                                                    <Accordion.Toggle
                                                        as={Card.Header}
                                                        eventKey={`${faqIdx}`}
                                                        className="font-benton-bold faq"
                                                        style={{
                                                            border: "none",
                                                            background: "none",
                                                        }}
                                                        onClick={() =>
                                                            toggleAccordion(
                                                                faqIdx.toString()
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "space-between",
                                                                cursor: "pointer",
                                                                borderBottom:
                                                                    "none",
                                                            }}
                                                        >
                                                            {faq.label}
                                                            <span>
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="20"
                                                                    height="21"
                                                                    viewBox="0 0 20 21"
                                                                    fill="none"
                                                                    style={{
                                                                        transform:
                                                                            activeKey ===
                                                                            faqIdx.toString()
                                                                                ? "rotate(180deg)"
                                                                                : "rotate(0deg)",
                                                                    }}
                                                                >
                                                                    <path
                                                                        d="M16.5984 7.96094L11.1651 13.3943C10.5234 14.0359 9.47344 14.0359 8.83177 13.3943L3.39844 7.96094"
                                                                        stroke="black"
                                                                        strokeOpacity="0.32"
                                                                        strokeWidth="2"
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                    />
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </Accordion.Toggle>
                                                    <Accordion.Collapse
                                                        eventKey={`${faqIdx}`}
                                                    >
                                                        <Card.Body className="font-benton-book header p-3">
                                                            {faq.description[0]}
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        ))}
                                </div>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="simpleShadow my-3 py-3 justify-content-around">
                        <Col
                            xs={12}
                            md={11}
                            lg={9}
                            className="pb-4 pr-md-5 p-2"
                        >
                            <div
                                className="shareLinkBlock1 d-flex flex-row p-4 "
                                style={{
                                    backgroundColor: "rgba(0, 0, 0, 0.04)",
                                    gap: "1rem",
                                    borderRadius: "1rem",
                                }}
                            >
                                <ShareButton
                                    shareUrl={`https://modernspaaces.com/blogs/${BLOGS.DECODING_AREA_METRICS.slug}`}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}
