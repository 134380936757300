export const FooterLogo = ({ hideCta = false }) => {
    return (
        <div
            className="w-full"
            style={{ textAlign: "center", backgroundColor: "#151414" }}
        >
            <div
                className="content"
                style={{
                    position: "relative",
                    paddingTop: "2.5rem",
                    paddingBottom: "1.5rem",
                }}
            >
                <a
                    href="/"
                    className="logo"
                    style={{
                        background: "transparent",
                        borderWidth: "1rem",
                        position: "relative",
                    }}
                >
                    {hideCta ? (
                        <img
                            src="/images/IvyCounty/iconhomz-ms-logo.png"
                            style={{
                                pointerEvents: "none",
                                width: "auto",
                                height: "100%",
                            }}
                            alt="IconHomz-MS Footer Logo"
                        />
                    ) : (
                        <img
                            src="/images/logo-sq.svg"
                            style={{
                                pointerEvents: "none",
                                width: "100%",
                                height: "100%",
                            }}
                            alt="Modern Spaaces Footer Logo"
                        />
                    )}
                </a>
            </div>
        </div>
    );
};

export default FooterLogo;
