import { BLOGS } from "../components/BlogDetail/constants";
const image1 = "/images/Blogs/BlogRiseOfWomenHomeowners1.jpg";
const image2 = "/images/Blogs/BlogRiseOfWomenHomeowners2.jpg";
// const loanApproval = "/images/Blogs/LoanApproval.png";

const tableOfContents = [
    {
        id: "introduction",
        text: "Introduction",
    },
    {
        id: "womenInvestment",
        text: "What's driving Indian women to invest in real estate?",
    },
    {
        id: "whyBuyHome",
        text: "Why should a woman buy a home in her name in India?",
    },
    {
        id: "stampDutyRates",
        text: "What are the Concessional Stamp Duty Rates for Women Across States?",
    },
    {
        id: "propertyRegistration",
        text: "How do you register a property in your name?",
    },
    {
        id: "taxBenefits",
        text: "How do Women Benefit from Tax Deductions on Home Loans?",
    },
    {
        id: "loanApplication",
        text: "Step by Step guide for home loan application",
    },
    {
        id: "governmentSchemes",
        text: "Government Schemes Simplifying Homeownership for Underprivileged Women Home Buyers",
    },
    // {
    //     id: "pmayBenefits",
    //     text: "Benefits for Women Under PMAY",
    // },
    {
        id: "budgetHighlights",
        // text: (
        //     <a
        //         href="https://modernspaaces.com/blogs/budget-2024"
        //         className="external-link"
        //         target="_blank"
        //         rel="noreferrer"
        //     >
        text: "Highlights of the Union Budget 2024: Empowering Women and Girls",
        // </a>
        // ),
    },
    {
        id: "investmentTips",
        text: "Tips for Women Ready to Invest in Real Estate",
    },
    {
        id: "conclusion",
        text: "Conclusion",
    },
    {
        id: "faqs",
        text: "FAQs ( Frequently Asked Questions)",
    },
];

const TitleEl = ({ content }) => {
    const { id, text } = content;
    return (
        <div id={id} className="scroll-top">
            {text}
        </div>
    );
};

const data = {
    slug: BLOGS.RISE_OF_WOMEN_HOMEOWNERS.slug,
    image: image1,
    imageAlt: BLOGS.RISE_OF_WOMEN_HOMEOWNERS.title,
    title: BLOGS.RISE_OF_WOMEN_HOMEOWNERS.title,
    time: BLOGS.RISE_OF_WOMEN_HOMEOWNERS.time,
    publishedOn: BLOGS.RISE_OF_WOMEN_HOMEOWNERS.date,
    title1: "Table of Contents",
    description11: (
        <ul className="table-of-content">
            {tableOfContents.map(({ id, text }) => (
                <li key={id}>
                    <a
                        href={`#${id}`}
                        onClick={() => window.scrollToElement(`#${id}`)}
                    >
                        {text}
                    </a>
                </li>
            ))}
        </ul>
    ),
    title2: <TitleEl content={tableOfContents[0]} />,
    description21: (
        <>
            In India, women are often seen as the home managers—the ones who
            truly keep everything together. A home just doesn’t feel right
            without them, does it? Yet, it’s surprising how many women don’t
            actually own their homes; they usually live in homes that are solely
            owned by their father or spouses.
            <br></br>
            But that’s changing! More and more women are now recognising real
            estate as a powerful investment—whether it’s to secure their future
            in an era of rising costs or to finally own a space that’s truly
            theirs. No longer just caretakers of a home, they are becoming
            rightful owners, redefining what homeownership means on their own
            terms.
        </>
    ),

    title3: <TitleEl content={tableOfContents[1]} />,

    description31: (
        <>
            A recent survey in March 2024 by
            <a
                href="https://economictimes.indiatimes.com/industry/services/property-/-cstruction/anarock-survey-65-pc-women-prefer-investing-in-real-estate-20-pc-stocks-8-pc-gold-7pc-fds/articleshow/98429616.cms"
                className="external-link"
                target="_blank"
                rel="noreferrer"
            >
                {" "}
                Anarock Property Consultants{" "}
            </a>
            revealed a significant shift in investment preferences among Indian
            women. Over 60% are now choosing real estate over traditional
            investment options like gold. This trend is driven by several
            factors:
        </>
    ),
    list32: [
        {
            label: "Financial Independence",
            description:
                "As women's careers and incomes grow, they're seeking investments that offer long-term security and stability.",
        },
        {
            label: "Financial Security",
            description:
                "Real estate is a tangible investment, and in Indian society, it’s highly valued as a sign of financial stability. Owning a home represents the ultimate form of financial security.",
        },
        {
            label: "Emotional Security",
            description:
                "Homeownership also provides a sense of financial and emotional stability, creating a safe haven for their immediate family.",
        },
        {
            label: "Government Benefits",
            description:
                "Policies like reduced stamp duty rates and tax incentives encourage women to enter the real estate market.",
        },
    ],
    description33: (
        <>
            These factors combined have led to a surge in women's interest in
            real estate investment in India.
        </>
    ),

    title4: <TitleEl content={tableOfContents[2]} />,
    description41: (
        <>
            Buying a home in a woman's name in India comes with several
            advantages. Here’s why it’s a smart choice:
        </>
    ),
    list42: [
        {
            label: "Lower Interest Rates on Home Loans",
            description:
                "Women can often secure lower interest rates on home loans, making financing easy and affordable saving a significant amount over the loan's tenure.",
        },
        {
            label: "Promotes Women's Empowerment",
            description:
                "Encouraging property ownership in women’s names helps bridge the gender gap in real estate and contributes to women’s empowerment in society.",
        },
        {
            label: "Tax Benefits",
            description:
                "Women property owners can enjoy tax benefits, including deductions on home loan interest and property taxes, which can offer long-term financial advantages.",
        },
        {
            label: "Legal Protection",
            description:
                "When a property is registered in a woman’s name, it provides her with stronger legal rights over that property, often protecting her from future litigations.",
        },
        {
            label: "Security for Women",
            description:
                "In metro cities, especially Bangalore, where rental prices are skyrocketing, owning a home offers women not only a safe and secure living space but also the potential for future income.",
        },
        {
            label: "Lower Stamp Duty & Registration Charges",
            description:
                "Many states offer reduced stamp duty and registration fees for women buyers, which can lead to substantial savings compared to properties registered in a man’s name.",
        },
    ],
    title5: <TitleEl content={tableOfContents[3]} />,

    description51: (
        <>
            Several states in India are already offering concessional stamp duty
            rates to encourage female property ownership. Here’s a look at some
            of the states that have implemented these measures:
        </>
    ),
    table52header: [
        "STATE",
        "STAMP DUTY RATE FOR WOMEN",
        "STAMP DUTY RATE FOR MEN",
    ],
    table52: [
        {
            col1: "Assam",
            col2: "5%",
            col3: "6%",
        },
        {
            col1: "Bihar",
            col2: "5.7%",
            col3: "6.3%",
        },
        {
            col1: "Chhattisgarh",
            col2: "4%",
            col3: "5%",
        },
        {
            col1: "Goa",
            col2: "3.9%",
            col3: "4.9%",
        },
        {
            col1: "Haryana",
            col2: "5%",
            col3: "7%",
        },
        {
            col1: "Himachal Pradesh",
            col2: "5%",
            col3: "6%",
        },
        {
            col1: "Maharashtra",
            col2: "5%",
            col3: "6%",
        },
        {
            col1: "Rajasthan",
            col2: "5%",
            col3: "6%",
        },
        {
            col1: "Uttarakhand",
            col2: "3.75%",
            col3: "5%",
        },
    ],

    title6: <TitleEl content={tableOfContents[4]} />,
    description61: (
        <>
            If you have read our blog, we are sure that you are excited about
            your own home. Here’s a simple guide to help you through the
            process:
        </>
    ),
    list62: [
        {
            label: "1. Gather Your Essential Documents",
            list: [
                "You’ll need some identity proof—like your Aadhaar card, PAN card, or passport.",
                "Make sure you have proof of address, too. Things like voter ID or utility bills (electric bills, Gas bills) work great.",
                "Don’t forget the property-related documents, including the sale deed and any agreements.",
                "If you’re dealing with a builder or seller, obtain a No Objection Certificate (NOC) from them.",
                "You’ll also want to collect property tax receipts and proof of payment for the stamp duty and registration fees.",
            ],
        },
        {
            label: "2. Do a Thorough Check",
            list: [
                "It’s super important to verify the property’s ownership and legal status. This will help you avoid any complications down the line.",
            ],
        },
        {
            label: "3. Get Some Legal Help",
            list: [
                "Consulting a lawyer can be really helpful. They can explain the legal aspects and make sure everything goes smoothly.",
            ],
        },
        {
            label: "4. Make the Payment",
            list: [
                "You’ll need to pay the necessary stamp duty and registration fees, so keep that in mind.",
            ],
        },
        {
            label: "5. Evaluate the Property",
            list: [
                "It’s a good idea to get a property valuation report for tax purposes.",
            ],
        },
        {
            label: "6. Submit Your Documents",
            list: [
                "Take all the necessary documents to the Sub-Registrar's office.",
            ],
        },
        {
            label: "7. Complete the Registration Process",
            list: [
                "After everything is verified, your property will be registered in your name!",
            ],
        },
        {
            label: "8. Collect Your Registered Deed",
            list: [
                "Don’t forget to pick up the registered property deed from the Sub-Registrar's office for your records.",
            ],
        },
    ],

    title7: <TitleEl content={tableOfContents[5]} />,
    description71: (
        <>
            Women in India are increasingly taking the lead in real estate
            investments, enjoying unique benefits when applying for home loans.
            <a
                href="https://documents1.worldbank.org/curated/pt/505551587106658623/pdf/Her-Home-Housing-Finance-for-Women.pdf"
                className="external-link"
                target="_blank"
                rel="noreferrer"
            >
                {" "}
                In 2020 alone, women accounted for 11%{" "}
            </a>
            of all home loan borrowers, demonstrating a significant increase
            from previous years.
            <br></br>
            <br></br>
            <div className="font-benton-bold">
                Top Benefits of Women Taking Home Loans:
            </div>
        </>
    ),
    list72: [
        {
            label: "Lower Interest Rates",
            description:
                "Many financial institutions offer better rates to women homebuyers because they tend to have a good track record of repaying loans.",
        },
        {
            label: "Higher Loan-to-Value (LTV) Ratios",
            description:
                "Women homebuyers often qualify for higher LTV ratios, meaning they may receive a larger loan amount relative to the property's value. This can make it easier to purchase their dream home. However, it's important to remember that a higher LTV ratio may increase the interest rate and extend the loan tenure, depending on the lender's policies and risk assessment.",
        },
        {
            label: "Reduced Processing Fees",
            description:
                "Streamlined processes and lower fees can save women significant costs.",
        },
        {
            label: "Increased Loan Amounts and Extended Repayment Terms",
            description:
                "One notable benefit of housing loans for women is their potential access to larger loan amounts and longer repayment periods. Lenders are keen to attract female borrowers by offering higher home loans, with repayment terms extending up to 25 years. These enhanced benefits, along with more flexible eligibility criteria, present significant advantages for women seeking to invest in real estate.",
        },
        {
            label: "Interest Deduction on Home Loans",
            description:
                "Women can claim significant tax savings on the interest paid for home loans. Under Section 24(b) of the Income Tax Act, 1961, a deduction of up to ₹2 lakh is available for self-occupied properties. For non-self-occupied properties, there's no cap on the interest deduction.",
        },
        {
            label: "Principal Repayment Deduction",
            description:
                "Under Section 80C of the Income Tax Act, women can claim tax deductions not only on the interest paid on home loans but also on the principal repayment. This provision allows for a maximum deduction limit of ₹1.5 lakh per financial year.",
        },
        {
            label: "Joint Loan Benefits",
            description:
                "Tax benefits increase when women apply for home loans jointly with their spouses or close relatives. However, both must be co-owners of the property to qualify. So, it's not just women who can benefit from this arrangement; men can also take advantage of the joint loan benefit. By having their spouse as a co-owner, couples can enjoy greater financial advantages, making homeownership more affordable and manageable.",
        },
        {
            label: "Under-Construction Property Deductions",
            description:
                "For properties still under construction, the tax deductions start once possession is taken. The interest paid during construction can be claimed in five equal instalments, starting from the year of completion. ",
        },
    ],
    title8: <TitleEl content={tableOfContents[6]} />,
    list81: [
        {
            label: "Step 1: Loan Application",
            list: ["Fill out the home loan application form."],
        },
        {
            label: "Step 2: Document Submission",
            list: [
                <>
                    Provide all the necessary documents such as:
                    <ul className="pl-3">
                        <li>- Identity and address proof.</li>
                        <li>- Bank statements for the last 6 months.</li>
                        <li>
                            - Salary slips (for salaried individuals) or income
                            tax returns (for self-employed individuals).
                        </li>
                        <li>- Property documents.</li>
                    </ul>
                </>,
            ],
        },
        {
            label: "Step 3: Processing Fee Payment",
            list: [
                "Pay the processing fee to the bank or financial institution. This fee covers administrative and processing costs.",
            ],
        },
        {
            label: "Step 4: Document Verification",
            list: [
                "The bank verifies all submitted documents to ensure authenticity. This includes checking credit history, income stability, and property details.",
            ],
        },
        {
            label: "Step 5: Property Valuation and Legal Check",
            list: [
                "The bank conducts a legal and technical check on the property to ensure there are no disputes and that the property is worth the loan amount.",
            ],
        },
        {
            label: "Step 6: Sanction Letter Issuance",
            list: [
                "Once verification is complete, the bank issues a sanction letter stating the loan amount, interest rate, tenure, and other terms and conditions.",
            ],
        },
        {
            label: "Step 7: Loan Agreement Signing",
            list: [
                "After successful verification and property assessment, you’ll sign the loan agreement, agreeing to the bank’s terms and conditions.",
            ],
        },
        {
            label: "Step 8: Loan Disbursement",
            list: [
                "Once the agreement is signed, the bank disburses the loan amount either in full or in installments, depending on the property construction status.",
            ],
        },
    ],
    image2: image2,
    image2Alt: "Home Loan Application",
    title9: <TitleEl content={tableOfContents[7]} />,
    list91: [
        {
            label: (
                <a
                    href="https://www.mygov.in/campaigns/pmay-urban/"
                    className="external-link"
                    target="_blank"
                    rel="noreferrer"
                >
                    PMAY Benefits for Female Home Buyers in India
                </a>
            ),
            description: `The Pradhan Mantri Awas Yojana (PMAY) is a government initiative launched on June 25, 2015, aimed at providing affordable housing for the urban poor. It falls under the "Housing for All" program and focuses on reducing the housing shortage among economically weaker sections (EWS), low-income groups (LIG), and middle-income groups (MIG), as well as those living in slums.`,
        },
        {
            label: (
                <a
                    href="https://www.pmaycalculator.com/blog/pradhan-mantri-awas-yojana-benefits-for-women"
                    className="external-link"
                    target="_blank"
                    rel="noreferrer"
                >
                    Benefits for Women Under PMAY
                </a>
            ),
            description: (
                <>
                    Female homebuyers enjoy several exclusive benefits under
                    PMAY, making it easier for them to own their first home. Key
                    advantages include:
                </>
            ),
            list: [
                "Low-Interest Rates",
                "Subsidy on Home Loans",
                "Reduced Stamp Duty",
                "Tax Benefits",
                "No Prepayment Charges",
            ],
        },
    ],
    title10: (
        <TitleEl
            content={{
                id: "budgetHighlights",
                text: (
                    <a
                        href="https://modernspaaces.com/blogs/budget-2024"
                        className="external-link"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Highlights of the Union Budget 2024: Empowering Women
                        and Girls
                    </a>
                ),
            }}
        />
    ),

    description101: (
        <>
            <span className="font-benton-bold">
                Relief for Women Homebuyers
            </span>
            <br></br>
            In the Union Budget 2024, the Finance Minister introduced measures
            to lighten the financial load for women homebuyers. One key
            initiative encourages states to reduce high stamp duty rates, with
            additional reductions for properties purchased by women. This reform
            forms a vital part of the urban development strategy, making
            homeownership more accessible for women.
        </>
    ),

    title11: <TitleEl content={tableOfContents[9]} />,

    description111: (
        <>
            Ready to take that exciting step into homeownership? Owning a home
            is one of the most empowering ways to secure your financial future.
            With a few smart strategies, you can make the most of the benefits
            available to women homebuyers in India. Here’s your guide to
            finding—and securing—the perfect home:
        </>
    ),
    list112: [
        {
            label: "Know Your Budget & Financing Options",
            description:
                "Start by defining how much you’re comfortable spending without compromising other financial priorities. When exploring home loans, compare different lenders for the best rates—many offer special rates for women borrowers.",
        },
        {
            label: "Choose the Right Location",
            description:
                "Whether it’s close to work, near good schools, or in a neighbourhood with great amenities, pick a spot that suits your lifestyle. And think long-term—locations with growth potential often lead to better returns down the line.",
        },
        {
            label: (
                <a
                    href="https://modernspaaces.com/blogs/why-pre-launch-properties-are-tickets-to-your-perfect-home"
                    className="external-link"
                    target="_blank"
                    rel="noreferrer"
                >
                    Consider Pre-Launch Properties
                </a>
            ),
            description:
                "Looking to bag a great deal and unique perks? Pre-launch properties are a fantastic option. These are projects that haven't officially hit the market yet, so developers often offer lower prices and attractive incentives to early buyers. Not only could you save a good amount upfront, but by getting in early, you’ll also have a better chance to secure the best units, which tend to sell out quickly. Just remember to do your due diligence—checking the builder’s reputation and reviewing project approvals—to make sure it's a solid investment.",
        },
        {
            label: "Understand Your Tax Benefits",
            description:
                "Women homebuyers can take advantage of deductions on home loan interest and principal repayment. If you’re a first-time buyer, these tax breaks can really add up, helping you save in the long run.",
        },
        {
            label: "Check the Legal Details",
            description:
                "Before you buy, make sure the property has clear legal titles and approvals. A quick consultation with a legal expert can help you sail through the registration and paperwork without surprises.",
        },
        {
            label: "Prioritise Safety & Amenities",
            description:
                "Look for gated communities or societies with round-the-clock security for extra peace of mind. And amenities like a gym, pool, and parks can enhance your quality of life—and boost future resale value.",
        },
        {
            label: "Plan for the Long Haul",
            description:
                "Try to choose a property that can evolve with you. Whether it’s future family plans, work-from-home needs, or lifestyle changes, a flexible space will stay relevant as life unfolds.",
        },
    ],

    title12: <TitleEl content={tableOfContents[10]} />,
    description121: (
        <>
            Women, don’t hold back from pursuing your dream home! The landscape
            for women homebuyers in India is changing for the better. You are
            not just financially empowered; you're part of a growing movement
            that celebrates women’s roles in real estate.<br></br> You can take
            advantage of lower home loan interest rates and attractive income
            tax deductions, lower stamp duty making your journey toward
            homeownership even more achievable. These initiatives aren’t just
            about acquiring property—they’re about empowering you to take
            control of your financial future and helping you build a secure and
            fulfilling life.<br></br> P.S. You go, girl! So proud of you!
            <br></br> Ready to turn your dream home into reality? Modern Spaaces
            is here to guide you every step of the way! We offer comprehensive
            support, from finding your perfect forever home to financial
            consultancy and expert real estate advice.<br></br> Visit our
            website at{" "}
            <a
                href="https://modernspaaces.com"
                className="external-link"
                target="_blank"
                rel="noreferrer"
            >
                {" "}
                www.modernspaaces.com{" "}
            </a>{" "}
            or call us at 99 000 99 324
        </>
    ),

    Faq: <TitleEl content={tableOfContents[11]} />,

    faqs: [
        {
            label: "Q1: Can I purchase a property on my own as a single woman in India?",
            description: [
                "Yes, single women in India can independently purchase and own property without any legal restrictions. Additionally, many states provide incentives such as reduced stamp duty and lower interest rates on home loans for women buyers, making it an opportune time to invest in real estate.",
            ],
        },
        {
            label: "Q2: What documents are required for registering a property in a woman’s name?",
            description: [
                "Required documents include identity proof, address proof, property-related documents, NOC from the builder/seller, property tax receipts, and payment proof for stamp duty and registration fees",
            ],
        },
        {
            label: "Q3: Are there any specific government schemes or subsidies available for underprivileged women homebuyers?",
            description: [
                "Yes, the Pradhan Mantri Awas Yojana (PMAY) offers various benefits to underprivileged women homebuyers, including interest subsidies, reduced stamp duty, and priority in loan approvals.",
            ],
        },
        {
            label: "Q4: Can women claim tax benefits for properties bought in a joint name?",
            description: [
                <>
                    Yes, if both co-owners have income sources, they can claim
                    separate tax deductions on home loan interest and property
                    taxes.
                </>,
            ],
        },
    ],
};

export default data;
