import { Row } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
// AnchorButtonIcon,
// import SmartWaterMeter from "../../../images/SoulTree/SmartGreenMinimal/SmartGreenMinimalImage3.png";
// import crossVentilation from "../../../images/Soulace/smartgreenmininal/crossventilation.webp";
// import solarpowered from "../../../images/Soulace/smartgreenmininal/solarpowered.webp";
// import waterConservation from "../../../images/Soulace/smartgreenmininal/waterConservation.webp";
// import NaturalLight from "../../../images/Soulace/smartgreenmininal/naturalLight.webp";
import NextArrow from "../../../images/Common/NextArrow.svg";
import PrevArrow from "../../../images/Common/PrevArrow.svg";
import "./SmartGreenMinimal.scss";

import SwiperCore, { A11y, Navigation, Pagination, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const energyConservation =
    "/images/SereneHeights/SmartGreenMinimal/energy-conservation.jpg";

const waterConservation =
    "/images/SereneHeights/SmartGreenMinimal/water-conservation.jpg";
const rainwaterHarvesting =
    "/images/SereneHeights/SmartGreenMinimal/rainwater-harvesting.jpg";
const wtp = "/images/GreenStoreys/SmartGreenMinimal/wtp.jpg";
const stp = "/images/GreenStoreys/SmartGreenMinimal/stp.jpg";
const wasteManagement =
    "/images/GreenStoreys/SmartGreenMinimal/waste-management.jpg";

export default function SmartGreenMinimal({ inView }) {
    const SmartGreenMinimalData = [
        {
            title: "SOLAR ROOFTOP POWER PLANT",
            desc: "3 KWp On-Grid SRTPP per tower for Common area lighting",
            image: energyConservation,
            imgAlt: "SOLAR ROOFTOP POWER PLANT",
        },
        {
            title: "TERRACE RAINWATER",
            desc: "Run-off collected at harvesting tank and utilised for domestic purposes after filtration",
            image: rainwaterHarvesting,
            imgAlt: "TERRACE RAINWATER",
        },
        {
            title: "RAINWATER HARVESTING",
            desc: "Recharging pits ensure efficient management of rain water",
            image: waterConservation,
            imgAlt: "RAINWATER HARVESTING",
        },
        {
            title: "STP (SEWAGE TREATMENT PLANT)",
            desc: "STP treated water considered for flushing and landscaping",
            image: stp,
            imgAlt: "STP (SEWAGE TREATMENT PLANT)",
        },
        {
            title: "WTP (WATER TREATMENT PLANT)",
            desc: "WTP treated water to be supplied through water filtration & softening plant",
            image: wtp,
            imgAlt: "WTP (WATER TREATMENT PLANT)",
        },
        {
            title: "WASTE MANAGEMENT",
            desc: "Waste separate bins, garbage segregation, organic waste convertor",
            image: wasteManagement,
            imgAlt: "WASTE MANAGEMENT",
        },
    ];
    return (
        <section
            className={`section bg-white SmartGreenMinimal ${
                inView ? "inView" : "outView"
            }
    `}
        >
            <div className="SmartGreenMinimal__Container">
                <div className="SmartGreenMinimal__Row">
                    <div className="SmartGreenMinimal__Content">
                        <Heading
                            variant="h1"
                            classList="SmartGreenMinimal__title"
                            text="Smart, Sustainable, Efficient"
                        />
                        <Desc
                            classList="SmartGreenMinimal__desc my-3 "
                            text="Explore Green Storeys"
                        />
                        {/* <AnchorButtonIcon
              href="#"
              classList="SmartGreenMinimal__btn mt-3"
              text="Download Brochure"
              icon={false}
            /> */}
                    </div>
                    <div className="SmartGreenMinimal__Slider">
                        <Swiper
                            className="SmartGreenMinimalSlider"
                            id="SmartGreenMinimalSlider"
                            tag="section"
                            wrapperTag="ul"
                            loop={true}
                            spaceBetween={10}
                            keyboard={{
                                enabled: true,
                            }}
                            navigation={{
                                prevEl: ".ArrowButtonBlock__ArrowPrevSmart",
                                nextEl: ".ArrowButtonBlock__ArrowNextSmart",
                            }}
                            onSwiper={(swiper) => console.log(swiper)}
                            // onSlideChange={(swiper) =>
                            //   console.log("slide index changed to: ", swiper.activeIndex)
                            // }
                            onInit={(swiper) =>
                                console.log("Swiper initialized", swiper)
                            }
                            breakpoints={{
                                300: {
                                    slidesPerView: 1.2,
                                },

                                768: {
                                    slidesPerView: 3,
                                    spaceBetween: 3,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 3,
                                },
                                1300: {
                                    slidesPerView: 3,
                                    spaceBetween: 3,
                                },
                            }}
                        >
                            {SmartGreenMinimalData.map(
                                ({ title, desc, image, imgAlt }, index) => (
                                    <SwiperSlide tag="li" key={index}>
                                        <div className="-image-block">
                                            <img
                                                src={image}
                                                className="SmartGreenMinimal__image"
                                                alt={imgAlt}
                                            />
                                            <div className="-content-block">
                                                <Heading
                                                    variant="h5"
                                                    classList="-title"
                                                    text={title}
                                                />
                                                <Desc
                                                    classList="-desc mt-3 mb-0"
                                                    text={desc}
                                                />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )
                            )}
                        </Swiper>
                        <Row className="ArrowButtons">
                            <div className="ArrowButtonBlock">
                                <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowPrevSmart">
                                    <img
                                        src={PrevArrow}
                                        className="-image"
                                        alt="SmartGreenMinimal"
                                    />
                                </div>
                                <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowNextSmart">
                                    <img
                                        src={NextArrow}
                                        className="-image"
                                        alt="SmartGreenMinimal"
                                    />
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>
            </div>
        </section>
    );
}
