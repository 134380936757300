import React from "react";
import { Row } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
import GrassPattern from "../GrassPattern";
// import view1 from "../../../images/GreenStoreys/architectural/image2.jpg";
// import view2 from "../../../images/GreenStoreys/architectural/image3.jpg";
// import view3 from "../../../images/GreenStoreys/architectural/image4.jpeg";
// import view4 from "../../../images/GreenStoreys/architectural/image5.jpg";
// import view5 from "../../../images/GreenStoreys/architectural/image6.webp";
// import common  from "../../../images/Engrace/architectural/tobeused/render2.webp";
// import Culture from "../../../images/SoulTree/Architectural/Culture.webp";
// import MoreThanJust from "../../../images/SoulTree/Architectural/MoreThanJust.webp";
// import Genrationoftoday from "../../../images/SoulTree/Architectural/Genrationoftoday.webp";
// import ArchitecturalImage from "../../../images/SoulTree/Architectural/ArchitecturalImage.png";

import PrevArrow from "../../../images/Common/PrevArrow.svg";
import NextArrow from "../../../images/Common/NextArrow.svg";
import "./ArchitecturalSlider.scss";

import SwiperCore, {
    Navigation,
    Pagination,
    Scrollbar,
    A11y,
    Controller,
    Autoplay,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Controller, Autoplay]);

const view1 = "/images/GreenStoreys/architectural/80-open-space.jpg";
const view2 = "/images/GreenStoreys/architectural/stepped-light-tower.jpg";
const view3 = "/images/GreenStoreys/architectural/roof-garden.jpg";
const view4 = "/images/GreenStoreys/architectural/corridor.jpg";
const view5 = "/images/GreenStoreys/architectural/central-atrium.jpg";
const view6 = "/images/GreenStoreys/architectural/bricks.jpg";

export default function ArchitecturalSlider({ inView }) {
    const ArchitecturalSliderData = [
        {
            title: "80% Open Space",
            desc: "With 80% open space, experience a rare sense of freedom in a living environment that feels expansive, open, and deeply connected to nature.",
            image: view1,
            imgAlt: "80% Open Space",
        },

        {
            title: "Stepped Silhouette For Max Light Penetration",
            desc: "This unique design of the project welcomes sunlight into every corner, filling homes with natural light, comfort, and energy savings.",
            image: view2,
            imgAlt: "Stepped Silhouette For Max Light Penetration",
        },
        {
            title: "Roof Garden",
            desc: "The lush green spaces above create a peaceful retreat, a place to pause, relax, and connect with nature. *Selected Units only",
            image: view3,
            imgAlt: "Roof Garden",
        },
        {
            title: "Dedicated Corridor For Each Unit",
            desc: "Every home is designed with its own private corridor, ensuring a quiet and a personal space for every unit.",
            image: view4,
            imgAlt: "Dedicated Corridor For Each Unit",
        },
        {
            title: "The Central Atrium",
            desc: "A peaceful shared space, perfect for relaxing and connecting, adding a refreshing touch for everyone.",
            image: view5,
            imgAlt: "The Central Atrium",
        },
        {
            title: "Built with Clay Bricks",
            desc: "Experience the benefits of clay bricks—better insulation, durability, and eco-friendliness, for a home that’s built to last and care for the planet.",
            image: view6,
            imgAlt: "Built with Clay Bricks",
        },
    ];

    return (
        <section
            className={`section bg-white ArchitecturalSlider ${
                inView ? "inView" : "outView"
            }
    `}
        >
            <GrassPattern />
            <Row className="ArchitecturalSlider__Row">
                <Swiper
                    wrapperTag="ul"
                    className="ArchitecturalSliderLists"
                    slidesPerView={1}
                    speed={500}
                    // speed={3400}
                    spaceBetween={0}
                    effect={"fade"}
                    fadeEffect={{
                        crossFade: true,
                    }}
                    // observeParents={true}
                    // observer={true}

                    autoplay={true}
                    loop={true}
                    direction="horizontal"
                    pagination={{
                        clickable: true,
                        el: ".swiper-pagination-Architech",
                    }}
                    navigation={{
                        prevEl: ".ArrowButtonBlock__ArrowPrevArchitech",
                        nextEl: ".ArrowButtonBlock__ArrowNextArchitech",
                    }}
                >
                    {ArchitecturalSliderData.map(
                        ({ image, title, desc, imgAlt }, index) => (
                            <SwiperSlide
                                tag="li"
                                key={index}
                                className="-block-list"
                            >
                                <div className="-image-block">
                                    <img
                                        src={image}
                                        className="-image "
                                        alt={imgAlt}
                                    />
                                </div>
                                <div className="-architech-co-block">
                                    <div className="-architech-co-block__inner soulace">
                                        <Heading
                                            variant="h1"
                                            classList="-title"
                                            text={title}
                                        />
                                        <Desc
                                            classList="-desc my-3 "
                                            text={desc}
                                        />
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    )}
                </Swiper>
            </Row>
            <Row className="ArrowButtons">
                <div className="ArrowButtonBlock">
                    <div className="swiper-pagination swiper-pagination-Architech"></div>
                    <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowPrevArchitech">
                        <img
                            src={PrevArrow}
                            className="-image"
                            alt="ArchitecturalSlider"
                        />
                    </div>
                    <div className="ArrowButtonBlock__Arrow ArrowButtonBlock__ArrowNextArchitech">
                        <img
                            src={NextArrow}
                            className="-image"
                            alt="ArchitecturalSlider"
                        />
                    </div>
                </div>
            </Row>
        </section>
    );
}
