import { BLOGS } from "../components/BlogDetail/constants";
const image1 = "/images/Blogs/BlogOC&CC1.jpg";

const tableOfContents = [
    {
        id: "introduction",
        text: "Introduction",
    },
    {
        id: "certificateOfOccupancy",
        text: "What is a certificate of occupancy?",
    },
    {
        id: "ocImportance",
        text: "What is the Importance of OC (Occupancy Certificate)?",
    },
    {
        id: "partialOC",
        text: "What is a Partial Occupancy Certificate?",
    },
    {
        id: "gettingOC",
        text: "How do you get a certificate of Occupancy  (OC)?",
    },
    {
        id: "onlineStatus",
        text: "How to check OC ( Occupancy Certificate) status online?",
    },
    {
        id: "bangaloreOnlineCheck",
        text: "How do you check occupancy certificates online in Bangalore?",
    },
    {
        id: "offlineCheck",
        text: "How to check an Occupancy Certificate (OC) Offline?",
    },
    {
        id: "issuanceParameters",
        text: "What are the parameters checked before issuing an Occupancy Certificate?",
    },
    {
        id: "keyTakeaway",
        text: "Key takeaway:",
    },
    {
        id: "impactResaleValue",
        text: "OC can impact resale value and loan approval",
    },
    {
        id: "missingOCs",
        text: "Legal implications of missing OCs",
    },
    {
        id: "insuranceRequirement",
        text: "Do I need an Occupancy Certificate (OC) to get insurance for my property?",
    },
    {
        id: "completionCertificate",
        text: "Completion Certificate",
    },
    {
        id: "eligibility",
        text: "Who Can Apply?",
    },
    {
        id: "OCvsCC",
        text: "What is the difference between CC and OC?",
    },
    {
        id: "CCvcOCvsPC",
        text: "Completion Certificate Vs Occupancy Certificate Vs Possession Certificate ",
    },
    {
        id: "buyingWithoutOC",
        text: "Can I Buy a Property Without an OC?",
    },
    {
        id: "homeownerMeaning",
        text: "What does an OC mean for the Homeowner?",
    },
    {
        id: "bdaPanchayat",
        text: "What if my property is under BDA or Panchayat?",
    },
    {
        id: "legalAction",
        text: "What legal action can be taken if the builder fails to provide an OC?",
    },
    {
        id: "timeline",
        text: "How long does it take to obtain OC?",
    },
    {
        id: "conclusion",
        text: "Conclusion",
    },
    {
        id: "faqs",
        text: "FAQ's (Frequently Asked Questions)",
    },
];
const TitleEl = ({ content }) => {
    const { id, text } = content;
    return (
        <div id={id} className="scroll-top">
            {text}
        </div>
    );
};
const blogConstant = BLOGS.OC_CC;

const data = {
    slug: blogConstant.slug,
    image: image1,
    imageAlt: blogConstant.title,
    title: blogConstant.title,
    time: blogConstant.time,
    publishedOn: blogConstant.date,
    title1: "Table of Contents",
    description11: (
        <ul className="table-of-content">
            {tableOfContents.map(({ id, text }) => (
                <li key={id}>
                    <a
                        href={`#${id}`}
                        onClick={() => window.scrollToElement(`#${id}`)}
                    >
                        {text}
                    </a>
                </li>
            ))}
        </ul>
    ),
    title2: <TitleEl content={tableOfContents[0]} />,
    description21: (
        <>
            Finally, you have found your dream home after months of tireless
            searching! Now, mountains of paperwork threaten to turn your
            excitement into a headache. Do not let terms like OC and CC puzzle
            you. Having an idea about them can help you as a homebuyer to be
            more confident and make sensible decisions. So let us understand
            what OC & CC means so that you are ready to ask the right questions
            and be sure of investing in a safe and legal property.
        </>
    ),

    title3: <TitleEl content={tableOfContents[1]} />,

    description31: (
        <>
            An{" "}
            <span className="font-benton-bold">Occupancy Certificate (OC)</span>{" "}
            is an official approval granted by the local government body (BBMP,
            Panchayat) confirming that the building has been constructed
            according to approved plans and complies with all building codes,
            making it safe for occupancy.
            <br /> This certificate ensures transparency in the construction
            process and confirms compliance with statutory requirements. It also
            verifies that the building has access to essential facilities such
            as water, electricity, proper ventilation, fire safety measures, and
            sanitation.
        </>
    ),

    title4: <TitleEl content={tableOfContents[2]} />,
    list41: [
        {
            label: "Legal",
            description: (
                <>
                    An OC strengthens the claim of ownership and can help in
                    resolving any future disputes regarding rightful ownership.
                </>
            ),
        },

        {
            label: "Increased Value",
            description: (
                <>
                    Properties with a valid OC are generally considered safer
                    and more desirable by potential buyers.
                </>
            ),
        },

        {
            label: <>Safety and Security</>,
            description: (
                <>
                    An OC signifies that the building has been constructed
                    following safety regulations, minimising potential risks for
                    residents.
                </>
            ),
        },
        {
            label: <>Insurance Coverage</>,
            description: (
                <>
                    Insurance companies consider an OC a key factor when
                    evaluating risk. Without an OC, securing property insurance
                    can be difficult or expensive due to perceived higher risk.
                </>
            ),
        },
        {
            label: <>Trust in Builder</>,
            description: (
                <>
                    Without OC, developers/ builders cannot guarantee that they
                    have obtained all required authorisations and permissions
                    from the concerned authorities.
                </>
            ),
        },
    ],

    title5: <TitleEl content={tableOfContents[3]} />,
    description51: (
        <>
            A{" "}
            <span className="font-benton-bold">
                Partial Occupancy Certificate (POC) or Temporary Occupancy
            </span>
            certificate is generally issued to projects that are large-scale or
            have different phases in them. In that case, a Partial Occupancy
            certificate will be issued before the entire project is finished and
            it may not include all the planned amenities. However, this is only
            temporary, with a validity period extending until the project's
            completion.
            <br />
            Builders have seven years from the POC issuance date to finalise the
            project. Once complete, the developer has a 30-day window to apply
            for the OC, the official document signifying the building's
            compliance with safety and livability standards.
        </>
    ),

    title6: <TitleEl content={tableOfContents[4]} />,

    description61: (
        <>
            Once the project has been completed, the builder, promoter, or
            developer needs to apply for the Occupancy Certificate (OC) through
            the RERA website. The OC is generally granted within 30 days of form
            submission.
            <br></br>
            The builder has to upload the following documents:
        </>
    ),

    list62: [
        "Certificate of Project Commencement",
        "Certificate of Project Completion",
        "Fire and pollution Certificates",
        "No Objection Certificate (NOC)",
        "Construction and Approval Plans",
        "A document detailing the floor area, certified by an authorised architect.",
        "A photograph of the completed structure along with a copy of the approved building plan.",
        "Photos demonstrating rainwater harvesting and solar panels.",
        "Tax assessment documents and the most recent property tax receipt.",
    ],
    description63: (
        <>
            A homeowner, with access to the building plan, can also play a key
            role by applying for the OC after submitting the necessary
            documents.
        </>
    ),
    title7: <TitleEl content={tableOfContents[5]} />,
    description71: (
        <>
            RERA mandates builders to update project information regularly on
            the corresponding state's RERA website. This often includes the OC
            status.
        </>
    ),
    list72: [
        "Visit your state's RERA website and search for your project using its name or developer details.",
        "If available online, you can conveniently download or view the OC directly.",
    ],

    title8: <TitleEl content={tableOfContents[6]} />,
    description81: (
        <>
            <>
                1. Log on to the BBMP's official website:
                <a
                    href="https://bbmp.gov.in/"
                    className="external-link"
                    target="_blank"
                    rel="noreferrer"
                >
                    (https://bbmp.gov.in/).
                </a>
            </>
            <br />
            <>
                2. Navigate to the section dedicated to "Online Services" or
                "Citizen Services."
            </>
            <br />
            <>
                3. Enter details like your property address, application number,
                or other relevant information to access the certificate or its
                status.
            </>
            <br />
            <>4. Now you can verify the Occupancy Certificate.</>
        </>
    ),
    title9: <TitleEl content={tableOfContents[7]} />,
    list91: [
        {
            label: <>Step 1</>,
            description: <>Find your nearest and relevant BBMP Office</>,
        },
        {
            label: <>Step 2</>,
            description: (
                <>Request for the Occupancy Certificate Application Form</>
            ),
        },
        {
            label: <>Step 3</>,
            description: (
                <>
                    Complete the Application Form
                    <br />
                    Key details include the property's address, BBMP khata
                    number, property identification number (PID), builder
                    information, and your ownership details.
                </>
            ),
        },
        {
            label: <>Step 4</>,
            description: (
                <>
                    Compile the Required Documentation
                    <br />
                    Building plan approvals, completion certificates, current
                    property tax receipts, and any other relevant paperwork.
                    Having a complete set of documents avoids delays.
                </>
            ),
        },
        {
            label: <>Step 5</>,
            description: <>Submit the application and the fees.</>,
        },
        {
            label: <>Step 6</>,
            description: <>Obtain Acknowledgement of Receipt</>,
        },
        {
            label: <>Step 7</>,
            description: <>Wait for the Review</>,
        },
        {
            label: <>Step 8</>,
            description: <>Visit the BBMP office in case of any issue</>,
        },
        {
            label: <>Step 9</>,
            description: <>Collect Your Occupancy Certificate</>,
        },
        {
            label: <>Step 10</>,
            description: <>Verify the Occupancy Certificate Details</>,
        },
    ],

    title10: <TitleEl content={tableOfContents[8]} />,
    description101: (
        <>
            An occupancy certificate is issued only when the following protocols
            are followed:
        </>
    ),
    list102: [
        "Compliance with the legally approved construction plans.",
        "Building codes and regulations are followed throughout the construction process.",
        "Basic amenities like drainage, ventilation, electricity, fire, safety, and sanitation facilities should be present.",
        "NOC, other clearances from the appointed authority, including the fire department, Airport Authority of India (AAI)",
    ],

    title11: <TitleEl content={tableOfContents[9]} />,
    description111: (
        <>
            An{" "}
            <span className="font-benton-bold">Occupancy Certificate (OC)</span>{" "}
            confirms that the building meets the applicable building codes and
            is safe for occupation. There may be ongoing work or items left
            unfinished within the building, mainly if it is a large project or
            involves phased occupancies.{" "}
        </>
    ),

    title12: <TitleEl content={tableOfContents[10]} />,
    description121: (
        <>
            Having an OC can positively impact the resale value and ease loan
            approval for a property. Without an OC, the property may face legal
            risks and a decline in market value. Prospective buyers may view
            properties without an OC with suspicion, leading to lower resale
            value. Furthermore, securing a home loan without an OC may be more
            difficult, as some lenders may offer loans on a case-by-case basis,
            depending on various factors such as the location, property type,
            and borrower's financial profile.
        </>
    ),
    title13: <TitleEl content={tableOfContents[11]} />,
    description131: (
        <>
            A lack of an OC can complicate matters for both buyers and sellers
            in the event of a dispute or future legal issues. An Occupancy
            Certificate (OC) creates numerous legal implications for buyers and
            sellers. For example, if a buyer purchases a property without an OC,
            they may be unable to register the property and may not be able to
            take possession, or the lender may not approve the loan. Without an
            OC, sellers cannot prove to buyers that the property is free of any
            legal issues or defects.
        </>
    ),

    title14: <TitleEl content={tableOfContents[12]} />,
    description141: (
        <>
            The OC means the construction has been completed according to
            approved building plans and meets safety standards. Insurance
            companies rely on these verifications to assess the risk associated
            with insuring the property. Without an OC, the homeowners may not
            receive comprehensive coverage or the insurance company might charge
            a higher premium due to the perceived higher risk.
        </>
    ),
    title15: <TitleEl content={tableOfContents[13]} />,
    description151: (
        <>
            A Completion Certificate (CC) signifies the official completion of
            your building project and its compliance with local regulations.
            Here's a breakdown of the process:
        </>
    ),

    title16: <TitleEl content={tableOfContents[14]} />,
    description161: (
        <>
            The developer or builder responsible for the construction project is
            authorised to apply for the Completion Certificate.
        </>
    ),

    title17: <TitleEl content={tableOfContents[15]} />,
    table171header: [
        "Aspect",
        "Completion Certificate",
        "Occupancy Certificate",
    ],
    table171: [
        {
            col1: "Purpose",
            col2: "Verifies construction compliance with approved plans and regulatory criteria.",
            col3: "Certifies that the building is safe and adheres to all local building laws and regulations.",
        },
        {
            col1: "Issued After",
            col2: "Construction is completed as per sanctioned plans.",
            col3: "A completion Certificate is obtained.",
        },
        {
            col1: "Regulatory Reference",
            col2: "Section 2(q) of the RERA Act.",
            col3: "Section 2(ZF) of the RERA Act.",
        },
        {
            col1: "Key Criteria",
            col2: "Compliance with approved building plans.  Adherence to height restrictions, distance from road, etc.",
            col3: "Compliance with all building laws. Assurance of safety for occupancy. Adherence to regulatory standards.",
        },
        {
            col1: "Legal Status",
            col2: "Legal document confirming construction compliance.",
            col3: "Legal document confirming the building is safe for occupancy.",
        },
    ],

    title18: <TitleEl content={tableOfContents[16]} />,
    description181: (
        <>
            The terms Completion Certificate, Occupancy Certificate, and
            Completion Certificate may seem similar, but they each serve
            distinct purposes. When a housing project is fully finished with all
            necessary amenities, it receives a{" "}
            <span className="font-benton-bold">"completion certificate"</span>{" "}
            from the authorities. This signifies that the project has met all
            regulatory requirements and adheres to relevant laws and by-laws.
            <br />
            An <span className="font-benton-bold">
                "occupancy certificate"
            </span>{" "}
            is granted when authorities are satisfied that the property is safe
            for occupancy. It confirms that the construction has been completed
            in compliance with building codes and regulations, allowing
            residents to move into the premises legally.
            <br />
            On the other hand, a{" "}
            <span className="font-benton-bold">
                “possession certificate”
            </span>{" "}
            serves as evidence that ownership of the property has transferred
            from the builder to the buyer. It is provided by the builder and
            typically includes the date on which the buyer formally takes
            possession of the property.
        </>
    ),
    table182header: [
        "Certificate",
        "Issued by",
        "Purpose",
        "Focus",
        "Impact",
        "Requirement For possession",
    ],
    table182: [
        {
            col1: "Completion Certificate",
            col2: "Local authorities (Municipal Corporation, BDA, Panchayat)",
            col3: "Verifies project completion according to approved plans and regulations",
            col4: "Construction completion and adherence to regulations",
            col5: "Project completion milestone",
            col6: "Not required",
        },
        {
            col1: "Occupation Certificate",
            col2: "Local authorities (Municipal Corporation, BDA, Panchayat)",
            col3: "Verifies building safety and compliance with building codes for occupancy",
            col4: "Building safety and habitability",
            col5: "Legal permission to occupy",
            col6: "Required for legal occupancy",
        },
        {
            col1: "Possession Certificate",
            col2: "Builder / Developer",
            col3: "Confirms transfer of ownership and possession date",
            col4: "Ownership transfer and possession rights",
            col5: "Formalises ownership transfer",
            col6: "Not required, but often precedes occupancy",
        },
    ],

    title19: <TitleEl content={tableOfContents[17]} />,
    description191: <>It's generally not recommended. Here's why:</>,
    list192: [
        {
            label: <>Legal Hurdles</>,
            description: (
                <>
                    The resale of a property without an OC can be difficult
                    because most buyers do not want to buy a home without it.
                </>
            ),
        },
        {
            label: <>Safety Concerns</>,
            description: (
                <>
                    The absence of an OC might indicate issues with the
                    building's compliance with safety regulations.
                </>
            ),
        },
        {
            label: <>Complications in Loans from financial institutions</>,
            description: (
                <>
                    Securing a home loan can be challenging if the property
                    lacks an OC.
                </>
            ),
        },
    ],

    title20: <TitleEl content={tableOfContents[18]} />,
    description201: <>A house/ apartment should have an OC because -</>,

    list202: [
        "OC implies the safety of the project and its compliance with the standard protocols.",
        "It is illegal to sell a property without an OC because it is considered to be an unauthorised structure.",
        "Without an OC you are not the legal owner of the property.",
        "In the absence of an OC, the legal authorities can initiate legal action against the homeowner.",
        "Possession of OC makes it easier to obtain a home loan.",
    ],
    title21: <TitleEl content={tableOfContents[19]} />,
    description211: (
        <>
            <span className="font-benton-bold">BDA & Panchayat OC Process</span>{" "}
            <br />
            For properties under BDA or Panchayat jurisdiction, obtaining an
            Occupancy Certificate (OC) involves the local governing body. Here's
            the breakdown: <br />
            <span className="font-benton-bold">
                Local Authority Conducts Inspections:
            </span>{" "}
            The local authority meticulously inspects the property to ensure:
        </>
    ),
    list212: [
        {
            label: <>Compliance with Approved Plans</>,
            description: <>Matches the blueprints submitted for approval.</>,
        },
        {
            label: <>Building Regulation Adherence</>,
            description: (
                <>Meets all relevant construction and safety regulations.</>
            ),
        },
        {
            label: <>Overall Safety Standards</>,
            description: <>Provides a safe and habitable environment.</>,
        },
    ],
    description213: (
        <>
            Only if the property fulfils all the above requirements will the
            local authority grant the OC, signifying it's safe for occupancy.
        </>
    ),
    title22: <TitleEl content={tableOfContents[20]} />,
    description221: (
        <>
            Under Section 11(4)(b) of The Real Estate (Regulation and
            Development) Act, 2016, the promoter must obtain the Occupancy
            Certificate (OC) from the appropriate authority and ensure its
            availability to the buyers.
            <br /> If a builder fails to provide an OC despite repeated
            requests, homeowners can take the following legal actions:
        </>
    ),
    list222: [
        {
            label: <>File a Legal Complaint</>,
            description: (
                <>
                    According to the Real Estate Regulatory Authority (RERA),
                    you can file a legal complaint against the builder or
                    developer for his inability to provide the OC to the RERA
                    Appellate Tribunal.
                </>
            ),
        },
        {
            label: <>File an RTI (Right to Information)</>,
            description: (
                <>
                    You can file an RTI application to the BBMP/ BMRDA authority
                    to understand the reasons for the delay in the project and
                    know the status of the OC.
                </>
            ),
        },
        {
            label: <>Serve a Legal Notice</>,
            description: (
                <>
                    Serving a legal notice to the builder can put additional
                    pressure on them to provide the OC.
                </>
            ),
        },
        {
            label: <>Claim Compensation</>,
            description: (
                <>
                    RERA has specified compensation from the builder in cases of
                    delays and non-compliance.
                </>
            ),
        },
    ],
    title23: <TitleEl content={tableOfContents[21]} />,
    description231: (
        <>
            The process typically takes anywhere around 30 to 90 days. It
            involves inspections, documentation review, and ensuring that the
            building meets all safety and regulatory standards before it can be
            occupied.
        </>
    ),
    title24: <TitleEl content={tableOfContents[22]} />,
    description241: (
        <>
            To sum up, both the{" "}
            <span className="font-benton-bold">Occupancy Certificate (OC)</span>{" "}
            and
            <span className="font-benton-bold">
                Certificate of Occupancy (OC)
            </span>{" "}
            are crucial documents in property ownership. Though they are often
            used interchangeably, they serve the same purpose: to officially
            confirm that a newly constructed building is structurally sound and
            complies with legal and safety standards. <br />
            These certificates are issued after thorough inspections by local
            authorities, such as the BBMP or Panchayat, to ensure the building
            adheres to zoning ordinances, building codes, and safety
            regulations. This includes verifying that the building meets
            essential requirements for safe habitation, such as fire safety,
            sanitation, ventilation, and access to basic utilities like water
            and electricity.
            <br /> In short, obtaining the OC or Certificate of Occupancy is a
            key step in the process of property ownership, providing legal
            assurance that the building is fit for use.
        </>
    ),
    Faq: <TitleEl content={tableOfContents[23]} />,

    faqs: [
        {
            label: "Q1: What if the builder does not give the Occupancy Certificate?",
            description: [
                <>
                    If the builder is delaying or refusing to provide the OC,
                    talk to a real estate lawyer. They can guide you on sending
                    a legal notice or taking further action.
                </>,
            ],
        },
        {
            label: "Q2: Can I buy the property in case the builder does not have the Occupancy Certificate?",
            description: [
                <>
                    It is not advisable due to potential legal issues, safety
                    concerns, and loan complications.
                </>,
            ],
        },
        {
            label: "Q3: Can I sell my building without an occupancy certificate?",
            description: [
                <>
                    Selling a property without an Occupancy Certificate (OC) is
                    not recommended because it can lower the property's value
                    and make it less attractive to buyers.
                </>,
            ],
        },
    ],
};

export default data;
