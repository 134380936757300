import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { BrowserRouter as Router } from "react-router-dom";
import ProjectApp from "./ProjectApp";

const rootElement = document.getElementById("root");
const projectElement = document.getElementById("project-root");

if (rootElement) {
    ReactDOM.render(
        <Router>
            <App />
        </Router>,
        rootElement
    );
}

if (projectElement) {
    ReactDOM.render(
        <Router>
            <ProjectApp />
        </Router>,
        projectElement
    );
}
