import { BLOGS } from "../components/BlogDetail/constants";
const image1 = "/images/Blogs/BlogDecodingArea1.jpg";
const image2 = "/images/Blogs/BlogDecodingArea2.jpg";
const image3 = "/images/Blogs/BlogDecodingArea3.jpg";
const image4 = "/images/Blogs/BlogDecodingArea4.jpg";
const image5 = "/images/Blogs/BlogDecodingArea5.jpg";
// const loanApproval = "/images/Blogs/LoanApproval.png";

const tableOfContents = [
    {
        id: "introduction",
        text: "Introduction",
    },
    {
        id: "carpetArea",
        text: "What is Carpet Area?",
    },
    {
        id: "carpetAreaInclusions",
        text: "What is included in the carpet area and what is not?",
    },
    {
        id: "reraCarpetArea",
        text: (
            <>
                What is
                <a
                    href="https://modernspaaces.com/blogs/rera-how-it-benefits-homeowners-and-developers"
                    className="external-link"
                    target="_blank"
                    rel="noreferrer"
                >
                    {" "}
                    RERA
                </a>{" "}
                Carpet Area?
            </>
        ),
    },
    {
        id: "reraCarpetAreaVsCarpetArea",
        text: "What is the Difference Between RERA Carpet Area and Carpet Area?",
    },
    {
        id: "usableArea",
        text: "What is Usable Area?",
    },
    {
        id: "buyerConsiderations",
        text: "What Should Homebuyers Consider: RERA Carpet Area vs Usable Area?",
    },
    {
        id: "takeaway",
        text: "So, what's the takeaway here?",
    },
    {
        id: "builtUpArea",
        text: "What is Built-Up Area & Super Built-Up Area?",
    },
    // {
    //     id: "reraUnderstanding",
    //     text: "Even though RERA doesn't use these for pricing anymore, understanding them can be beneficial.",
    // },
    {
        id: "loadingFactor",
        text: "What is Loading Factor?",
    },
    {
        id: "loadingFactorCalculation",
        text: "How to Calculate the Loading Factor?",
    },
    {
        id: "uds",
        text: "What is Undivided Share (UDS)?",
    },
    {
        id: "udsImportance",
        text: "Why is UDS Important?",
    },
    {
        id: "guidelines",
        text: "Essential Guidelines for Homebuyers",
    },
    {
        id: "conclusion",
        text: "Conclusion",
    },
    {
        id: "faqs",
        text: "FAQs (Frequently Asked Questions)",
    },
];

const TitleEl = ({ content }) => {
    const { id, text } = content;
    return (
        <div id={id} className="scroll-top">
            {text}
        </div>
    );
};

const data = {
    slug: BLOGS.DECODING_AREA_METRICS.slug,
    image: image1,
    imageAlt: BLOGS.DECODING_AREA_METRICS.title,
    title: BLOGS.DECODING_AREA_METRICS.title,
    time: BLOGS.DECODING_AREA_METRICS.time,
    publishedOn: BLOGS.DECODING_AREA_METRICS.date,
    title1: "Table of Contents",
    description11: (
        <ul className="table-of-content">
            {tableOfContents.map(({ id, text }) => (
                <li key={id}>
                    <a
                        href={`#${id}`}
                        onClick={() => window.scrollToElement(`#${id}`)}
                    >
                        {text}
                    </a>
                </li>
            ))}
        </ul>
    ),
    title2: <TitleEl content={tableOfContents[0]} />,
    description21: `You've been exploring listings and visiting experience centres, searching for your dream home. But those real estate terms agents throw around can feel like a whole new language, right? You know "super" from Superman, but now they're talking about "super-built up area" and "carpet area." Puzzled? Don’t worry—we understood the assignment. This blog will help you decode the real estate jargon, so you can confidently visit experience centres, understand brochures, and find a home that truly meets your needs—without getting misled.`,
    title3: <TitleEl content={tableOfContents[1]} />,

    description31: (
        <>
            Carpet area also known as the usable carpet area means simply the
            space within the apartment/villa where you can lay down a carpet or
            arrange your furniture. So in real estate when someone uses the word
            carpet area it means the space available in the apartment for your
            personal use and it excludes walls, balcony and terrace. To
            calculate this area, measure the length and width of the room from
            wall to wall and multiply those measurements.
            <br></br>
            Here’s a simple example:
            <br />
            If a room measures 12 feet in length and 10 feet in width, you would
            calculate the carpet area as follows:
        </>
    ),
    list31: [
        {
            label: "Length",
            description: "12 feet",
        },
        {
            label: "Width",
            description: (
                <>
                    10 feet
                    <br></br>
                    <span className="font-benton-bold">
                        Carpet Area = Length × Width = 12 feet × 10 feet = 120
                        square feet
                    </span>
                </>
            ),
        },
    ],
    description32: (
        <>
            So, the carpet area of this room is 120 square feet, which
            represents the usable space within the inner walls.
        </>
    ),
    image2: image2,
    image2Alt: "Carpet Area",

    title4: <TitleEl content={tableOfContents[2]} />,

    list41: [
        {
            label: "Included",
            list: [
                "Kitchen",
                "Bathrooms",
                "Rooms (bedrooms, living rooms, dining rooms etc.)",
                "Staircases within the property (if applicable)",
            ],
        },
        {
            label: "Excluded",
            list: [
                "Balcony/private balcony",
                "Shared spaces or common areas (like hallways and lobbies)",
                "Utility ducts",
                "walls",
            ],
        },
    ],
    title5: <TitleEl content={tableOfContents[3]} />,

    description51: (
        <>
            So according to RERA, it's the "net usable floor area of an
            apartment," so it excludes external walls, service shaft areas, and
            private balconies or terraces. But the only difference is that—it
            includes the area covered by the internal partition walls of your
            apartment.
            <br></br>
            <span className="font-benton-bold">
                RERA Carpet Area = Net Usable Area of the Apartment
            </span>
            (excluding external walls, terrace, balcony, and verandah) +
            Internal Partition Wall Areas.
        </>
    ),

    title6: <TitleEl content={tableOfContents[4]} />,
    description61: (
        <>
            The main distinction between the RERA Carpet Area and general Carpet
            Area (Usable Carpet Area) lies in the treatment of internal
            partition walls. RERA Carpet Area includes the thickness of these
            internal walls, whereas the general Carpet Area does not. As a
            result, RERA Carpet Area is typically about 5% larger than general
            Carpet Area.
        </>
    ),

    title7: <TitleEl content={tableOfContents[5]} />,
    description71: (
        <>
            Usable Area ( Carpet Area + Balcony/ Terraces) refers to the
            functional space within your home that you can truly live in and
            enjoy. It goes beyond just the primary rooms like bedrooms, living
            areas, and kitchens, capturing those valuable extras, such as:
        </>
    ),
    list72: [
        {
            label: "Balconies",
            description:
                "Your personal outdoor retreat, ideal for a breath of fresh air right off the living room.",
        },
        {
            label: "Terraces",
            description:
                "A versatile space, perfect for relaxing, cultivating a garden, or hosting gatherings under the stars.",
        },
    ],
    title8: <TitleEl content={tableOfContents[6]} />,
    description81: (
        <>
            When you're on the journey of buying a home, it’s super important to
            grasp the difference between RERA carpet area and usable area. Think
            of the RERA carpet area as a useful guideline—it gives you a sense
            of how much space you're working with. However, it doesn’t always
            show you the actual usable space in your apartment. <br></br>While
            the RERA carpet area gives you a standardised measurement, don’t
            forget to consider the{" "}
            <span className="font-benton-bold">usable area</span>, which might
            include those lovely balconies and other practical living spaces.
            So, when you're checking out a home, remember to look at the usable
            area. It gives you a better idea of how much space you really have
            for your daily life and all the activities you enjoy!
        </>
    ),
    title9: <TitleEl content={tableOfContents[7]} />,
    description91: (
        <>
            As a homebuyer, you should really focus on the usable area when
            evaluating properties. Sure, the RERA carpet area is important for
            understanding regulations, but looking at the usable carpet area
            gives you a clearer idea of what you’ll actually have to enjoy in
            your new home.
            <br></br>
            To get a good sense of the usable carpet area, take some time to
            measure and total up all the spaces inside your flat. This way,
            you’ll have a solid understanding of the living space available to
            you, making your home-buying experience much more informed and
            satisfying!
            <br></br>
            Now that you understand what carpet area and RERA carpet area mean,
            let's move on to another set of commonly used terms in real estate:
            built-up area and super built-up area. Ready?
        </>
    ),
    title10: <TitleEl content={tableOfContents[8]} />,
    description101: (
        <>
            Even though RERA doesn’t use these for pricing anymore,
            understanding them can be beneficial.
        </>
    ),
    list102: [
        {
            label: "Built-Up Area",
            description: (
                <>
                    The built-up area is the total space within a property's
                    walls, including the carpet area and wall thickness. It
                    covers the carpet area plus spaces taken up by exterior
                    walls and is also called the “plinth area.”
                    <br></br>
                    It includes the carpet area, walls, balconies and is usually
                    about 10-20% more than the carpet area due to wall thickness
                    and shared areas.
                    <div className="imagepadding">
                        <img
                            src={image3}
                            alt={"Built-Up Area"}
                            class="image margin border"
                        />
                    </div>
                </>
            ),
        },
        {
            label: "Super Built-Up Area",
            description: (
                <>
                    This is referred to as the “saleable area” which includes
                    your living space plus shared areas like common corridors,
                    lifts, and clubhouses. This takes the built-up area a step
                    further by including common spaces like lifts, staircases,
                    and amenities such as clubhouses and swimming pools.
                    <br></br>
                    However, it excludes areas like compound walls, open-air
                    swimming pools, parks, driveways, open sports facilities,
                    inaccessible garden spaces, play areas, underground tanks,
                    water reservoirs, and septic tanks. It’s typically 25-30%
                    more than the built-up area.
                    <br></br>
                    <br></br>
                    Example:{" "}
                    <span className="font-benton-bold">
                        If the Carpet Area is
                    </span>{" "}
                    1500 sq. ft.
                    <br></br>
                    <span className="font-benton-bold">
                        RERA Carpet Area will be{" "}
                    </span>
                    Approximately 1575 sq. ft.
                    <br></br>
                    <span className="font-benton-bold">
                        Built-Up Area:
                    </span>{" "}
                    Around 1650-1800 sq. ft.<br></br>
                    <span className="font-benton-bold">
                        Super Built-Up Area:
                    </span>{" "}
                    Typically ranges between 2062.5 and 2340 sq. ft. (assuming a
                    30% increase).
                </>
            ),
        },
    ],
    image4: image4,
    image4Alt: "Super Built-Up Area",
    image5: image5,
    image5Alt: "Carpet Area, Built Up Area, Super Built-Up Area",

    // table104header: [
    //     "Aspect",
    //     "Carpet Area",
    //     "Built Up Area",
    //     "Super Built Up Area",
    // ],
    // table104: [
    //     {
    //         col1: "Definition",
    //         col2: "Complex, time-consuming",
    //         col3: "Relatively simple",
    //     },
    //     {
    //         col1: "Time and Effort",
    //         col2: "Requires significant time",
    //         col3: "Quicker and more straightforward",
    //     },
    //     {
    //         col1: "Stress Level",
    //         col2: "Requires managing numerous details and decisions by the owner",
    //         col3: "Builders are responsible for all the hassles",
    //     },
    //     {
    //         col1: "Budget Oversight",
    //         col2: "High risk of overspending",
    //         col3: "Fixed budget; any cost increases are managed by the builder",
    //     },
    //     {
    //         col1: "Warranty Period",
    //         col2: "None",
    //         col3: "RERA-certified apartments come with a 5-year warranty for structural defects post-possession",
    //     },
    //     {
    //         col1: "Loan Approval",
    //         col2: "More complex approval process for construction loans.",
    //         col3: "Easier loan approval process for apartments",
    //     },
    //     {
    //         col1: "Customisation",
    //         col2: "High",
    //         col3: "Limited Scope",
    //     },
    // ],
    title11: <TitleEl content={tableOfContents[9]} />,

    description111: (
        <>
            The difference between the super built-up area and the carpet area
            is called the Loading Factor. This accounts for shared spaces like
            elevators, the lobby, and amenities that aren’t exclusive to your
            home.
        </>
    ),

    title12: <TitleEl content={tableOfContents[10]} />,
    description121: (
        <>
            Here’s a simple formula:{" "}
            <span className="font-benton-bold">
                Super Built-Up Area = Carpet Area × (1 - Loading Factor)
            </span>{" "}
            For example, if the loading factor is 0.3 (or 30%), that means 30%
            of the super built-up area is common space.
        </>
    ),

    title131: <TitleEl content={tableOfContents[11]} />,

    description131: (
        <>
            When you buy an apartment in a residential complex, you also acquire
            an undivided share of the land (UDS) on which the entire building
            stands. This UDS doesn’t have specific boundaries but represents
            your portion of the overall property, similar to being a shareholder
            in a company.
        </>
    ),

    title14: <TitleEl content={tableOfContents[12]} />,

    description141: (
        <>
            The total apartment cost typically includes both the building
            structure and the land, with the land cost tied to your UDS. If the
            property undergoes redevelopment or is acquired by the government,
            your compensation is calculated based on your UDS. Furthermore, if
            you receive a designated parking space, this land allocation is
            added to your UDS, so be sure the developer registers the parking
            space in your name to ensure hassle-free ownership benefits.
        </>
    ),
    title15: <TitleEl content={tableOfContents[13]} />,
    list151: [
        {
            label: "Do Your Market Research",
            description:
                "Before diving into negotiations, take some time to research the current market rates for properties of similar size and location. Check This knowledge will give you a solid foundation for determining a fair price related to the carpet area, built-up area, and super built-up area.",
        },
        {
            label: "Request Detailed Floor Plans",
            description:
                "Don’t hesitate to ask the builder or developer for detailed floor plans that outline the layout and dimensions of each room, including wall thickness. This information will allow you to independently calculate the carpet area, built-up area, and super built-up area, ensuring you’re getting a fair deal.",
        },
        {
            label: "Ask for a Cost Breakdown",
            description: (
                <>
                    To gain clarity on how costs are calculated, request a
                    detailed breakdown from the builder or developer for the
                    carpet area, built-up area, and super built-up area. This
                    approach will help you understand the complete cost
                    structure of the property, including any additional charges
                    or fees that may apply.<br></br> Modern Spaaces, like other
                    reputed builders, provides detailed information about floor
                    plans that helps buyers make decisions confidently.
                </>
            ),
        },
    ],
    title16: <TitleEl content={tableOfContents[14]} />,
    description161: (
        <>
            Knowing terms like carpet area, loading factor, and the difference
            between built-up and super built-up areas equips you to make
            informed decisions during your home-buying journey. It empowers you
            to compare properties accurately, assess usable living space, and
            ensure you get the space that meets your needs. By understanding
            these terms and leveraging the protections offered by RERA, you can
            buy your dream home with confidence!
            <br></br>
            Ready to find your ideal RERA-certified and spacious home? Visit
            <a
                href="https://modernspaaces.com"
                className="external-link"
                target="_blank"
                rel="noreferrer"
            >
                {" "}
                www.modernspaaces.com{" "}
            </a>
            or contact us at +91 900 800 67 68 today!
        </>
    ),

    Faq: <TitleEl content={tableOfContents[15]} />,

    faqs: [
        {
            label: "Q1: What is the difference between carpet area and built-up area?",
            description: [
                "The carpet area refers to the actual usable space within the apartment, while the built-up area includes the carpet area plus walls and balconies/terraces.",
            ],
        },
        {
            label: "Q2: How does RERA define carpet area?",
            description: [
                "Under RERA, carpet area is defined as the net usable floor area of an apartment, excluding external walls and service shafts, but including internal partition walls.",
            ],
        },
        {
            label: "Q3: What is a loading factor and why is it important?",
            description: [
                "The loading factor indicates the proportion of common areas relative to the overall super built-up area. A lower loading factor is preferable as it signifies a larger usable living space.",
            ],
        },
        {
            label: "Q4: How can I calculate the loading factor?",
            description: [
                <>
                    The loading factor can be calculated using the formula:
                    <br></br>
                    <span className="font-benton-bold">
                        Super Built-Up Area = Carpet Area × (1 - Loading
                        Factor).
                    </span>
                    <br></br>
                    For example, a loading factor of 0.3 means that 30% of the
                    super built-up area is common space.
                </>,
            ],
        },
        {
            label: "Q5: What are the benefits of RERA for homebuyers?",
            description: [
                "RERA promotes transparency in the real estate market, standardised practices, builds trust, ensures fair pricing based on carpet area, and protects buyers from changes during construction.",
            ],
        },
    ],
};

export default data;
