import { Col, Container, Row } from "react-bootstrap";
import { Desc, Heading } from "../../Global/Typography";
import GrassPattern from "../GrassPattern";

import "./Banner.scss";

const logo = "/images/GreenStoreys/Banner/logo.svg";
const BannerImage = "/images/GreenStoreys/Banner/banner.webp";

export default function Banner({ inView }) {
    return (
        <section
            className={`section overflow-hidden pb-0 ${
                inView ? "inView" : "outView"
            }`}
        >
            <GrassPattern />
            <Container className="pos-rel my-md-3">
                <Row>
                    <Col xs={11} md={10} lg={7} className="pr-5 py-5 mt-5">
                        <img
                            src={logo}
                            alt="logo"
                            className="SoulTreeLogo msSoulTreeBannerLogo msSoulTreeBannerLogo--sm"
                            style={{ height: "6rem" }}
                        />

                        <Heading
                            classList="my-4 pr-md-3"
                            variant="h1"
                            text="Boutique 2 & 3 BHK Apartments
              "
                        />
                        <Desc
                            classList="pr-md-3"
                            text="Green Storeys by Modern Spaaces offer unique 2 & 3 BHK homes with 80% Open Space"
                        />
                    </Col>
                </Row>

                <img
                    src={logo}
                    alt="GreenStoreys"
                    className="SoulTreeLogo msSoulTreeBannerLogo msSoulTreeBannerLogo--md"
                    style={{ height: "10rem" }}
                />

                {/* NEW IMAGE */}
                {/* <Row>
          <Col md={12} className="p-0">
            <div className="md-container-wrap">
              <img src={BannerImage} alt="logo" className="-banner-image" />
            </div>
          </Col>
        </Row> */}
            </Container>
            <div className="md-container-wrap">
                <img
                    src={BannerImage}
                    alt="GreenStoreys by Modern Spaaces"
                    className="-banner-image"
                />
            </div>
            <GrassPattern />
        </section>
    );
}
