import { BLOGS } from "../components/BlogDetail/constants";
const image1 = "/images/Blogs/BlogSpotlightOnMS-1.jpeg";
const image2 = "/images/Blogs/BlogSpotlightOnMS-2.jpeg";
const image3 = "/images/Blogs/BlogSpotlightOnMS-3.jpeg";

const tableOfContents = [
    {
        id: "introduction",
        text: "Introduction",
    },
    {
        id: "greba",
        text: "What is GREBA?",
    },
    {
        id: "special-recognition",
        text: "A Special Recognition for Our Leader",
    },
    {
        id: "building-future",
        text: "Building the Future of Real Estate",
    },
    {
        id: "more-than-homes",
        text: "More Than Just Homes - Building Spaces That Matter",
    },
    {
        id: "sets-up-apart",
        text: "What Sets Us Apart?",
    },
    {
        id: "winning-this-award",
        text: "What Winning This Award Means",
    },
    {
        id: "find-your-home",
        text: "Ready to Find Your Dream Home?",
    },
];

const TitleEl = ({ content }) => {
    const { id, text } = content;
    return (
        <div id={id} className="scroll-top">
            {text}
        </div>
    );
};
const blogConstant = BLOGS.SPOTLIGHT_ON_MS;

const data = {
    slug: blogConstant.slug,
    image: image1,
    imageAlt: blogConstant.title,
    title: blogConstant.title,
    time: blogConstant.time,
    publishedOn: blogConstant.date,
    // title1: "Table of Contents",
    // description11: (
    //     <ul className="table-of-content">
    //         {tableOfContents.map(({ id, text }) => (
    //             <li key={id}>
    //                 <a
    //                     href={`#${id}`}
    //                     onClick={() => window.scrollToElement(`#${id}`)}
    //                 >
    //                     {text}
    //                 </a>
    //             </li>
    //         ))}
    //     </ul>
    // ),
    description11: (
        <>
            Modern Spaaces was in the spotlight for a fantastic reason! Our
            inspiring leader, Novin Reddy, was honoured with the prestigious
            <span className="font-benton-bold">
                Young Achievers Award
            </span> by <span className="font-benton-bold">GREBA 2024</span>{" "}
            (Global Real Estate Brand Awards). This accolade recognises his
            unwavering dedication, strategic vision, and passion for crafting
            homes where families can truly thrive.
        </>
    ),

    title2: <TitleEl content={tableOfContents[1]} />,
    description21: (
        <>
            Established in 2015, the
            <a
                href="https://greba.in/"
                className="external-link"
                target="_blank"
                rel="noreferrer"
            >
                {" "}
                Global Real Estate Brand Award (GREBA){" "}
            </a>
            recognises leading players in the real estate industry. Award
            categories range from Affordable Segment to Ultra Luxury, Green
            Homes, Commercial, Retail, and Young Achiever. <br />
            This year, Exhibition Asia and Brimbus partnered to present the 2024
            GREBA Awards alongside the prestigious 8th annual ARC Review
            Conclave. The event, India's top for real estate celebration and
            networking saw over 150 awards chosen from more than 1,500
            nominations. Winning a GREBA Award is a significant honour and a
            symbol of achievement.
        </>
    ),
    image3: image3,

    title3: <TitleEl content={tableOfContents[2]} />,

    description31: (
        <>
            At Modern Spaaces, Novin leads with a collaborative approach,
            overseeing sales and customer relationship management (CRM),
            strategic planning, and investment.
            <br /> Here's what Novin had to say:
            <div className="imagepadding">
                <img
                    src={image2}
                    alt="Peace of Mind"
                    class=" margin"
                    style={{
                        objectFit: "contain",
                        width: "100%",
                    }}
                />
            </div>
            <br />
            Novin prioritises employee well-being and engagement, believing that
            a motivated and supported workforce is key to the sustained success
            of Modern Spaaces.
        </>
    ),

    title4: <TitleEl content={tableOfContents[3]} />,
    description41: (
        <>
            We at Modern Spaaces are passionate about exceeding expectations and
            constantly strive to push boundaries, redefining what's possible in
            real estate. This award serves as a strong validation of our
            commitment to excellence and motivates us to continue delivering
            exceptional experiences for our clients.
        </>
    ),

    title5: <TitleEl content={tableOfContents[4]} />,
    description51: (
        <>
            Modern Spaaces, established in 2019, has become Bangalore's
            fastest-growing real estate developer. We don't just build homes; we
            create places that matter, blending functionality with human-centric
            design. <br />
            We aspire to be more than just a big brand. Our goal is to be
            recognised for our genuine dedication to our residents and the
            community, aiming to make a positive difference in the real estate
            sector.
        </>
    ),

    title6: <TitleEl content={tableOfContents[5]} />,
    list61: [
        {
            label: <>Dynamic Team</>,
            description: (
                <>
                    Young yet adept, our team thrives on turning the impossible
                    into reality. We constantly learn, adapt, and refine our
                    work and processes, embracing every lesson learned.
                </>
            ),
        },
        {
            label: <>Innovation and Learning</>,
            description: (
                <>
                    We leverage technology to create impactful, future-ready
                    spaces. Every project is a step towards making a positive
                    difference in how people live.
                </>
            ),
        },
    ],

    title7: <TitleEl content={tableOfContents[6]} />,
    description71: (
        <>
            GREBA fuels our passion at Modern Spaaces, validates our efforts,
            boosts morale, and inspires us to keep pushing boundaries and
            embrace innovation. This award acknowledges Novin's visionary
            leadership and our dedication to creating exceptional living spaces.
            We're incredibly grateful and energised to tackle the exciting
            projects ahead!
        </>
    ),
    title8: <TitleEl content={tableOfContents[7]} />,
    description81: (
        <>
            Let us help you find the perfect space to live your dreams! Contact
            Modern Spaaces today and turn your dream home into a reality.
        </>
    ),
};

export default data;
