import { useState } from "react";
import { InView } from "react-intersection-observer";
// RelatedArticles

import Banner from "./Banner/index.js";
export default function BlogDecodingAreaMetrics() {
    const [inViewBanner, setInViewBanner] = useState(false);
    // const [inViewRelatedArticles, setInViewRelatedArticles] = useState(false);
    const [
        inViewShareBlogDecodingAreaMetrics,
        setInViewShareBlogDecodingAreaMetrics,
    ] = useState(false);
    return (
        <>
            <InView
                className="bgPattern"
                onChange={setInViewShareBlogDecodingAreaMetrics}
                triggerOnce={true}
            >
                {/* <ShareBlogDecodingAreaMetrics
          shareUrl={
            "https://modernspaaces.com/blogs/defference-between-a-khata-b-khata-and-its-impact-on-property"
          }
          inView={inViewShareBlogDecodingAreaMetrics}
        ></ShareBlogDecodingAreaMetrics> */}
            </InView>
            <InView className="" onChange={setInViewBanner} triggerOnce={true}>
                <Banner inView={inViewBanner}></Banner>
            </InView>
        </>
    );
}
